import Cookies from 'universal-cookie';
const cookies = new Cookies();

export function checkLogin() {
    var access_token = cookies.get('jwtToken', { path: '/' });
    var userName = cookies.get('userName', { path: '/' });
    var name = cookies.get('user_name', { path: '/' });
    var userRole = cookies.get('userRole', { path: '/' });
    if (access_token && access_token !== "null" && access_token !== "undefined") {
        const userData = {
            access_token, userName, userRole, name
        }
        return userData
    }
    else {
        return false
    }
}
