import React, { forwardRef, useContext, useRef } from "react";
import use3DViewer from "./use3DViewer";
import StateContext from "../../context/stateGlobal/StateContext";
import use3DViewerRight from "./use3DViewerRight";
import "./Product3D.css";
import FileDropZone from "./DragDrop";

const Product3D = ({ jsonData, isAddIpr }) => {
	const globalState = useContext(StateContext);

	let viewerRef = useRef(null);
	let viewerRefRight = useRef(null);
	const { viewer, actions } = use3DViewer({
		container: viewerRef,
	});
	const { viewerRight, actionsRight } = use3DViewerRight({
		container: viewerRefRight,
	});

	return (
		<div
			className="ProductViewer"
			style={{ width: "100%", height: "100%" }}
		>
			{isAddIpr && <FileDropZone />}
			<div ref={viewerRef} className="viewerleft"></div>
			<div
				ref={viewerRefRight}
				className="viewerright"
				style={{ display: "none" }}
			></div>
		</div>
	);
};

export default Product3D;
