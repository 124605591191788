
import { useState, useEffect, useRef, useContext } from 'react';
import { setupViewer } from './setupViewer';
import useActions3d from './useActions3d';
import StateContext from '../../context/stateGlobal/StateContext';
import { ObjectGrp3D } from './utils/ObjectGrp3D';
import jsonData from "../../input.json"
import LoadingManger3D from './LoadingManger3D';
import * as THREE from 'three';
import { addModelsInThreeViewerRight } from './use3DViewerRight';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';

const use3DViewer = ({ container }) => {
    const globalState = useContext(StateContext);
    const [viewer, setViewer] = useState({});
    const animationFrameId = useRef(null); // for the rendering loop
    const actions = useActions3d(viewer)

    let { patientId, caseId, uuid, token } = useParams();
    let decryptedPayload;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (token) {
        decryptedPayload = CryptoJS.AES.decrypt(decodeURIComponent(token), process.env.REACT_APP_TOKEN_SECRET_KEY).toString(CryptoJS.enc.Utf8);
        const decodedPayload = JSON.parse(decryptedPayload)
        if (decodedPayload.exp && decodedPayload.exp < currentTimestamp) {
            console.error('Link invalid or expired')
        } else {
            patientId = decodedPayload.patient_id
            caseId = decodedPayload.treatment_plan_version
            uuid = decodedPayload.uuid
        }
    }

    async function initializeViewer() {
        if (container.current && !globalState.initialized) {
            // Viewer Setup
            let urlPathName = window.location.pathname
            if (urlPathName == '/add-ipr-data') {
                    globalState.setAddingIprDataBool(true);
                    globalState.setInitialized(true);   
                    globalState.playPauseRef.current.style.pointerEvents = 'none'
                    globalState.playPauseRef.current.style.display = 'none'
                    globalState.footerRef.current.style.display = 'none'
            }
            else {
                // globalState.dragAndDropRef.current.style.display = 'none'   
                const { threeViewer } = setupViewer(container, animationFrameId);
                window.threeViewer = threeViewer
                // Load models
                let time = performance.now();
                let loadingMgr = new LoadingManger3D(threeViewer)
                
                // Extract the path parameters from the URL
                let patientData = await loadingMgr.extractPatientCaseData(patientId, caseId, uuid)
                globalState.setSelectedTreatmentPlan(parseInt(loadingMgr.caseId))
                // Load treatment plan of the patient
                let patientPlans = await loadingMgr.getTreatmentPlan(loadingMgr.patientId)
                globalState.setTempTreatmentData(patientPlans.data)
                // debugger
                // if(patientPlans.data){
                //     let patientIprData= patientPlans.data[parseInt(loadingMgr.caseId) - 1].attributes.ipr_data
                //     globalState.setIprBool(Array.isArray(patientIprData) && (patientIprData).length>0)
                // }
                if(patientPlans.data){                
                    let patientPlan = patientPlans.data.find(plan => plan.attributes.treatment_plan_version === loadingMgr.caseId);
                    let patientIprData = patientPlan.attributes.ipr_data;
                    globalState.setIprBool(Array.isArray(patientIprData) && patientIprData.length > 0);
                }
                globalState.setIprData(patientData)
                await loadingMgr.processJsonData(patientData, 900); // 15 minutes expiration time
                
                
                // Load models
                globalState.setViewer(threeViewer);
                setViewer(threeViewer);
                globalState.setInitialized(true);
                globalState.setSubsetGroup(patientData.models.length)
                globalState.setCurrentSetup(0)
                
                // adding a tooth model to threeViewerRight so that user can use split view
                threeViewer.threeViewerRight = globalState.threeViewerRight
                addModelsInThreeViewerRight(threeViewer, patientData.models.length)
            }
            
        }
    }
    
    useEffect(() => {
        initializeViewer();
        
        // Cleanup function to stop the animation loop when the component unmounts
        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };
        
    }, [container]);
    
    useEffect(() => {
        if (globalState.viewer) {
            globalState.setActions(actions);
        }
    }, [globalState.viewer]); // React to changes in viewer
    
    useEffect(() => {
        if (globalState.selectedTreatmentPlan > 0 && globalState.tempTreatmentData) {
            // globalState.setIprData(globalState.tempTreatmentData[globalState.selectedTreatmentPlan - 1].attributes.ipr_data)
            let patientPlan = globalState.tempTreatmentData.find(plan => parseInt(plan.attributes.treatment_plan_version) === globalState.selectedTreatmentPlan);
            let patientIprData = patientPlan.attributes.ipr_data;
            globalState.setIprData(patientIprData);
        }
    }, [globalState.selectedTreatmentPlan, globalState.tempTreatmentData])
    
    useEffect(() => {
        if(globalState.isFileDropped){

            const { threeViewer } = setupViewer(container, animationFrameId);
            // window.threeViewer = threeViewer
            // globalState.setCurrentSetup(0)
            // globalState.setSubsetGroup(10);
            globalState.setViewer(threeViewer);
            window.threeViewer = threeViewer
            setViewer(threeViewer);
        }
    },[globalState.isFileDropped])
    return { viewer, actions };
};

export default use3DViewer;
