import React from "react";
import "../Header/HeaderNav.css";

export default function HeaderLogo({type}) {
	let publicUrl = process.env.PUBLIC_URL;
	return (
		<>
			<div className={`HeaderNav-logo ${type === "login" ? "HeaderNav-logo__block" : ""}`}>
				<img src={`${publicUrl}/assets/img/Logo.svg`} alt="Logo" />
			</div>
		</>
	);
}
