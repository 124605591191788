import React, { useContext, useEffect, useRef, useState } from "react";
import "../Footer/Footer.css";
import StateContext from "../../context/stateGlobal/StateContext";
import FooterModelButton from "./FooterModelButton";

export default function Footer() {
	let publicUrl = process.env.PUBLIC_URL;
	const globalState = useContext(StateContext);
	const speedRef = useRef();
	/**
	 * Handles animation based on the selected speed.
	 */
	const handleAnimation = () => {
		// Retrieve the selected speed from the speedRef
		const selectedSpeed = speedRef.current.value;

		if (selectedSpeed === "0.1") {
			// Play animation with ID 12, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				70,
				globalState.subSetGroup,
				true,
				true
			);
		} else if (selectedSpeed === "0.25") {
			// Play animation with ID 17, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				50,
				globalState.subSetGroup,
				true,
				true
			);
		} else if (selectedSpeed === "0.5") {
			// Play animation with ID 17, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				30,
				globalState.subSetGroup,
				true,
				true
			);
		} else if (selectedSpeed === "1") {
			// Play animation with ID 17, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				15,
				globalState.subSetGroup,
				true,
				true
			);
		} else if (selectedSpeed === "2") {
			// Play animation with ID 5, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				5,
				globalState.subSetGroup,
				true,
				true
			);
		} else {
			// Default case: Play animation with ID 12, using the globalState's subSetGroup, and enable looping and autoplay
			globalState.actions.playAnimation(
				12,
				globalState.subSetGroup,
				true,
				true
			);
		}
		setIsImage1Visible(!isImage1Visible);
	};

	/**
	 * Handles the speed button click event.
	 */
	const handleSpeedButton = () => {
		// Get the value of the speed reference
		const speedValue = speedRef.current.value;

		// Play the animation based on the speed value
		switch (speedValue) {
			case "0.1":
				globalState.actions.playAnimation(
					70,
					globalState.subSetGroup,
					false,
					false
				);
				break;
			case "0.25":
				globalState.actions.playAnimation(
					50,
					globalState.subSetGroup,
					false,
					false
				);
				break;
			case "0.5":
				globalState.actions.playAnimation(
					30,
					globalState.subSetGroup,
					false,
					false
				);
				break;
			case "1":
				globalState.actions.playAnimation(
					15,
					globalState.subSetGroup,
					false,
					false
				);
				break;
			case "2":
				globalState.actions.playAnimation(
					5,
					globalState.subSetGroup,
					false,
					false
				);
				break;
			default:
				globalState.actions.playAnimation(
					12,
					globalState.subSetGroup,
					false,
					false
				);
				break;
		}
	};
	const [buttonCount, setButtonCount] = useState(globalState.subSetGroup);
	useEffect(() => {
		setButtonCount(globalState.subSetGroup);
	}, [globalState.subSetGroup]);

	useEffect(() => {
		if (globalState.actions) {
			globalState.actions.setPlayPauseRef(globalState.playPauseRef);
		}
	}, [buttonCount]);

	const handleForwardToothModelClick = () => {
		if (globalState.currentSetup === globalState.subSetGroup - 1) {
			globalState.actions.setVisibleModel(1, globalState.subSetGroup);
			globalState.setCurrentSetup(0);
		} else {
			globalState.actions.setVisibleModel(
				globalState.currentSetup + 1,
				globalState.subSetGroup
			);
			globalState.setCurrentSetup(globalState.currentSetup + 1);
		}
	};

	const handlePreviousToothModelClick = () => {
		if (globalState.currentSetup === 0) {
			globalState.actions.setVisibleModel(
				globalState.subSetGroup,
				globalState.subSetGroup
			);
			globalState.setCurrentSetup(globalState.subSetGroup - 1);
		} else {
			if (globalState.currentSetup === 1) {
				globalState.actions.setVisibleModel(1, globalState.subSetGroup);
				globalState.setCurrentSetup(0);
			} else {
				globalState.actions.setVisibleModel(
					globalState.currentSetup - 1,
					globalState.subSetGroup
				);
				globalState.setCurrentSetup(globalState.currentSetup - 1);
			}
		}
	};

	const [isImage1Visible, setIsImage1Visible] = useState(true);

	// const handleImageClick = () => {
	//   setIsImage1Visible(!isImage1Visible);
	// };

	return (
		<div className="Footer-Section" ref={globalState.footerRef}>
			<div className="Footer-Section-Outer">
				<div className="Footer-Section-Inner">
					<div className="Footer-Section-ButtonAction">
						<button
							className="ButtonAction-Unicon ButtonAction-Unicon--left"
							onClick={handlePreviousToothModelClick}
							style={{
								display: globalState?.addingIprDataBool
									? "none"
									: "flex",
							}}
						>
							<img
								src={`${publicUrl}/assets/img/Union.svg`}
								alt="Union"
							></img>
						</button>
						<button
							className="ButtonAction-Polygon"
							style={{
								display: isImage1Visible ? "block" : "none",
							}}
							ref={globalState.playPauseRef}
							onClick={handleAnimation}
						>
							<img
								src={`${publicUrl}/assets/img/Polygon.svg`}
								alt="Polygon"
							></img>
						</button>
						<button
							className="ButtonAction-Polygon"
							style={{
								display: isImage1Visible ? "none" : "block",
							}}
							onClick={handleAnimation}
						>
							<img
								src={`${publicUrl}/assets/img/Pause.svg`}
								alt="Polygon"
							></img>
						</button>
						<button
							className="ButtonAction-Unicon ButtonAction-Unicon--right"
							onClick={handleForwardToothModelClick}
							style={{
								display: globalState?.addingIprDataBool
									? "none"
									: "flex",
							}}
						>
							<img
								src={`${publicUrl}/assets/img/Union-right.svg`}
								alt="Union-Right"
							></img>
						</button>
						<div
							className="Footer-Section-Speed"
							style={{
								display: globalState?.addingIprDataBool
									? "none"
									: "flex",
							}}
						>
							<div className="Speed-Text">Speed</div>
							<select
								className="form-select speed-select-dropdown"
								aria-label="Default select example"
								onChange={handleSpeedButton}
								ref={speedRef}
								defaultValue="0.5"
							>
								<option value="2">2x</option>
								<option value="1">1x</option>
								<option value="0.5">0.5x</option>
								<option value="0.25">0.25x</option>
								<option value="0.1">0.1x</option>
								{/* <option value="2">2x</option> */}
							</select>
						</div>

						<div className="Footer-Section-CountBtn">
							{Array.from({ length: buttonCount }, (_, index) => (
								<FooterModelButton key={index} index={index} />
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
