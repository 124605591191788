import React, { useContext, useEffect } from "react";
import HeaderNav from "./Components/Header/HeaderNav";
import Content from "./Components/Content/Content";
import Footer from "./Components/Footer/Footer";
import "../src/index.css";
import "../src/css/responsive.css";
import LoadingBar from "./Components/Loader/loadingBar";
import CryptoJS from "crypto-js";
import { useParams } from "react-router-dom";
import stateContext from "./context/stateGlobal/StateContext";

function App() {
	const appDivRef = React.useRef(null);
	const globalState = useContext(stateContext);

	let linkExpired = false;

	let { patientId, caseId, uuid, token } = useParams();
	let decryptedPayload;
	const currentTimestamp = Math.floor(Date.now() / 1000);
	if (token) {
		decryptedPayload = CryptoJS.AES.decrypt(
			decodeURIComponent(token),
			process.env.REACT_APP_TOKEN_SECRET_KEY
		).toString(CryptoJS.enc.Utf8);
		const decodedPayload = JSON.parse(decryptedPayload);
		if (decodedPayload.exp && decodedPayload.exp < currentTimestamp) {
			linkExpired = true;
			alert("Link invalid or expired");
		} else {
			patientId = decodedPayload.patient_id;
			caseId = decodedPayload.treatment_plan_version;
			uuid = decodedPayload.uuid;
		}
	}

	return (
		<>
			{!linkExpired ? (
				<div
					className={`App ${
						globalState.isLoggedIn ? "loggedIn" : "notLoggedIn"
					}`}
					ref={appDivRef}
				>
					<HeaderNav appDivRef={appDivRef} shareLinkToken={token}/>
					<Content />
					<Footer />
					<LoadingBar />
				</div>
			) : null}
		</>
	);
}

export default App;
