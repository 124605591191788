import { useContext, useEffect, useRef } from "react";
import StateContext from "../../context/stateGlobal/StateContext";
import * as THREE from "three";
import { CSS2DObject } from "three/examples/jsm/renderers/CSS2DRenderer";
import "./Product3D.css";
import { createAccumulator, downloadJSONData } from "./utils/generateJsonData";
let iprIntialized = true;
export default function useActions3d(threeViewer) {
	const globalState = useContext(StateContext);
	const globalRef = useRef(globalState);
	let playPauseRef;
	let isImposed = false;

	useEffect(() => {
		globalRef.current = globalState;
	}, [globalState]);

	useEffect(() => {
		if (threeViewer.viewManager) {
			if (iprIntialized) {
				iprViewer(globalRef.current.iprBool);
			}
			iprIntialized = false;
		}
	}, [globalRef.current.viewer]);

	/**
	 * Sets the top view of the dental models.
	 */
	const setTopView = () => {
		const dentalModelsLoader = threeViewer.viewManager;
		dentalModelsLoader.setView(
			new THREE.Vector3(0, 150, 0),
			new THREE.Vector3(-Math.PI / 2, 0, 0),
			true,
		);
		threeViewer.scene.getObjectByName("maxillary").visible = false;
		threeViewer.scene.getObjectByName("mandibular").visible = true;

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			let dentalModelsLoaderRight = threeViewerRight.viewManager;
			dentalModelsLoaderRight.setView(
				new THREE.Vector3(0, 150, 0),
				new THREE.Vector3(-Math.PI / 2, 0, 0),
				true,
			);
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = false;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = true;
		}
		if (threeViewer.scene.getObjectByName("maxillaryClone") && isImposed) {
			threeViewer.scene.getObjectByName("maxillaryClone").visible = false;
			threeViewer.scene.getObjectByName("mandibularClone").visible = true;
		}
		globalRef.current.setToothOrientation("lower");
		if (globalRef.current.iprBool) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				true,
				"lower",
				false,
			);
		}
	};

	/**
	 * Sets the left view of the dental models.
	 */
	const setLeftView = () => {
		const dentalModelsLoader = threeViewer.viewManager;
		dentalModelsLoader.setView(
			new THREE.Vector3(-150, 0, 0),
			new THREE.Vector3(0, Math.PI / 2, 0),
			false,
		);
		threeViewer.scene.getObjectByName("mandibular").visible = true;
		threeViewer.scene.getObjectByName("maxillary").visible = true;

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			let dentalModelsLoaderRight = threeViewerRight.viewManager;
			dentalModelsLoaderRight.setView(
				new THREE.Vector3(-150, 0, 0),
				new THREE.Vector3(0, Math.PI / 2, 0),
				false,
			);
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = true;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = true;
		}
		globalRef.current.setToothOrientation("left");

		showOrHideImposedModel();
	};

	/**
	 * Sets the front view of the dental models.
	 */
	const setFrontView = () => {
		const dentalModelsLoader = threeViewer.viewManager;
		dentalModelsLoader.setView(
			new THREE.Vector3(0, 0, 150),
			new THREE.Vector3(0, 0, 0),
			false,
		);
		threeViewer.scene.getObjectByName("mandibular").visible = true;
		threeViewer.scene.getObjectByName("maxillary").visible = true;

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			let dentalModelsLoaderRight = threeViewerRight.viewManager;
			dentalModelsLoaderRight.setView(
				new THREE.Vector3(0, 0, 150),
				new THREE.Vector3(0, 0, 0),
				false,
			);
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = true;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = true;
		}
		if (globalRef.current.iprBool) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				true,
				false,
				true,
			);
		}
		globalRef.current.setToothOrientation("front");
		showOrHideImposedModel();
	};

	/**
	 * Sets the right view of the dental models.
	 */
	const setRightView = () => {
		const dentalModelsLoader = threeViewer.viewManager;
		dentalModelsLoader.setView(
			new THREE.Vector3(150, 0, 0),
			new THREE.Vector3(0, -Math.PI / 2, 0),
			false,
		);
		threeViewer.scene.getObjectByName("mandibular").visible = true;
		threeViewer.scene.getObjectByName("maxillary").visible = true;

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			let dentalModelsLoaderRight = threeViewerRight.viewManager;
			dentalModelsLoaderRight.setView(
				new THREE.Vector3(150, 0, 0),
				new THREE.Vector3(0, -Math.PI / 2, 0),
				false,
			);
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = true;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = true;
		}
		globalRef.current.setToothOrientation("right");

		showOrHideImposedModel();
	};

	/**
	 * Sets the bottom view of the dental models.
	 */
	const setBottomView = () => {
		const dentalModelsLoader = threeViewer.viewManager;
		dentalModelsLoader.setView(
			new THREE.Vector3(0, -150, 0),
			new THREE.Vector3(Math.PI / 2, 0, 0),
			true,
		);
		threeViewer.scene.getObjectByName("mandibular").visible = false;
		threeViewer.scene.getObjectByName("maxillary").visible = true;

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			let dentalModelsLoaderRight = threeViewerRight.viewManager;
			dentalModelsLoaderRight.setView(
				new THREE.Vector3(0, -150, 0),
				new THREE.Vector3(Math.PI / 2, 0, 0),
				true,
			);
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = false;
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = true;
		}

		if (threeViewer.scene.getObjectByName("mandibularClone") && isImposed) {
			threeViewer.scene.getObjectByName("mandibularClone").visible = false;
			threeViewer.scene.getObjectByName("maxillaryClone").visible = true;
		}
		globalRef.current.setToothOrientation("upper");
		if (globalRef.current.iprBool) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				true,
				"upper",
				false,
			);
		}
	};

	/**
	 * Hides the maxillary group of the dental models.
	 */
	const hideMaxillaryGroup = () => {
		threeViewer.scene.getObjectByName("maxillary").visible = false;
		threeViewer.scene.getObjectByName("mandibular").visible = true;

		if (threeViewer.scene.getObjectByName("maxillaryClone") && isImposed) {
			threeViewer.scene.getObjectByName("maxillaryClone").visible = false;
			threeViewer.scene.getObjectByName("mandibularClone").visible = true;
		}

		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = false;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = true;
		}
		globalRef.current.setToothOrientation("lower");
		if (globalRef.current.iprBool) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				true,
				"lower",
				false,
			);
		}
	};
	/**
	 * Play or pause the animation based on the given parameters.
	 *
	 * @param {number} speed - The speed of the animation.
	 * @param {number} noOfModels - The number of models in the animation.
	 * @param {boolean} playPauseBool - Boolean flag indicating whether to play or pause the animation.
	 * @param {boolean} init - Boolean flag indicating whether the animation is being initialized.
	 */
	const playAnimation = async (speed, noOfModels, playPauseBool, init) => {
		if (threeViewer.animationState && playPauseBool) {
			// Pause the animation if it is already playing and playPauseBool is true.
			threeViewer.animationState = false;
		} else if (!threeViewer.animationState && !init) {
			//Change of speed and models
			threeViewer.speed = speed;
			threeViewer.noOfModels = noOfModels;
		} else if (!threeViewer.animationState && init) {
			// Start the animation if it is not playing and it is an initialization.
			threeViewer.animationState = true;
			threeViewer.speed = speed;
			threeViewer.noOfModels = noOfModels;
			threeViewer.objectAnimation(
				5,
				noOfModels,
				speed,
				globalState.setCurrentSetup,
			);
		} else if (threeViewer.animationState && !init) {
			// Continue the animation if it is already playing and it is not an initialization.
			threeViewer.animationState = true;
			threeViewer.speed = speed;
			threeViewer.noOfModels = noOfModels;
			threeViewer.objectAnimation(
				5,
				noOfModels,
				speed,
				globalState.setCurrentSetup,
			);
		}
	};
	/**
	 * Sets the visibility of the specified model in the scene.
	 *
	 * @param {number} visibleModel - The model number to set as visible.
	 */
	const setVisibleModel = (
		visibleModel,
		totalModels = globalState.subSetGroup,
	) => {
		visibleModel = Number(visibleModel);

		// Validate if visibleModel is a valid number
		if (isNaN(visibleModel)) {
			console.error("visibleModel is not a valid number.");
			return; // Exit the function early
		}

		for (let i = 1; i <= totalModels; i++) {
			let maxillaryObjectName = `maxillary_${i}`;
			let mandibularObjectName = `mandibular_${i}`;

			// Check if the object names exist in the scene before trying to set visibility
			if (threeViewer.scene.getObjectByName(maxillaryObjectName)) {
				threeViewer.scene.getObjectByName(maxillaryObjectName).visible = false;
			}

			if (threeViewer.scene.getObjectByName(mandibularObjectName)) {
				threeViewer.scene.getObjectByName(mandibularObjectName).visible = false;
			}
		}

		let maxillaryObject = threeViewer.scene.getObjectByName(
			`maxillary_${visibleModel}`,
		);
		let mandibularObject = threeViewer.scene.getObjectByName(
			`mandibular_${visibleModel}`,
		);

		// Check if the objects exist in the scene before trying to set visibility
		if (maxillaryObject) {
			maxillaryObject.visible = true;
		} else if (!maxillaryObject) {
			for (let j = visibleModel; j > 0; j--) {
				maxillaryObject = threeViewer.scene.getObjectByName(`maxillary_${j}`);
				if (maxillaryObject) {
					maxillaryObject.visible = true;
					break;
				}
			}
		}

		if (mandibularObject) {
			mandibularObject.visible = true;
		} else if (!mandibularObject) {
			for (let k = visibleModel; k > 0; k--) {
				mandibularObject = threeViewer.scene.getObjectByName(`mandibular_${k}`);
				if (mandibularObject) {
					mandibularObject.visible = true;
					break;
				}
			}
		}
	};
	/**
	 * Hide the mandibular group in the threeViewer scene.
	 */
	const hideMandibularGroup = () => {
		globalRef.current.setToothOrientation("upper");
		// Set the 'maxillary' object's visibility to true
		threeViewer.scene.getObjectByName("maxillary").visible = true;

		// Set the 'mandibular' object's visibility to false
		threeViewer.scene.getObjectByName("mandibular").visible = false;
		// if imposed model is present in the scene
		if (threeViewer.scene.getObjectByName("mandibularClone") && isImposed) {
			threeViewer.scene.getObjectByName("mandibularClone").visible = false;
			threeViewer.scene.getObjectByName("maxillaryClone").visible = true;
		}
		//if split view is active
		if (threeViewer.threeViewerRight.params.element.style.display === "block") {
			let threeViewerRight = threeViewer.threeViewerRight;
			threeViewerRight.scene.getObjectByName("maxillaryRight").visible = true;
			threeViewerRight.scene.getObjectByName("mandibularRight").visible = false;
		}
		if (globalRef.current.iprBool) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				true,
				"upper",
				false,
			);
		}
	};

	/**
	 *Show/Hide the attachment in the threeViewer scene.
	 */
	const toggleAttachment = (inBool) => {
		threeViewer.objectGrp3D?.toggleAttachment(inBool);
		let threeViewerRight = threeViewer.threeViewerRight;
		toggleAttachmentInSplitView(inBool, threeViewerRight);
	};

	const toggleAttachmentInSplitView = (inBool, threeViewerRight) => {
		// this function is used to set the visibility of attachment in split view
		let maxillaryModel =
			threeViewerRight.scene.getObjectByName("maxillaryRight");
		let mandibularModel =
			threeViewerRight.scene.getObjectByName("mandibularRight");
		maxillaryModel.traverse((e) => {
			if (!e.isMesh) {
				return;
			} // eslint-disable-next-line
			if (
				e.name !== "Mandibular" &&
				e.name !== "Maxillary" &&
				!e.name.startsWith("Tooth_")
			) {
				e.visible = inBool;
			}
		});

		mandibularModel.traverse((e) => {
			if (!e.isMesh) {
				return;
			} // eslint-disable-next-line
			if (
				e.name !== "Mandibular" &&
				e.name !== "Maxillary" &&
				!e.name.startsWith("Tooth_")
			) {
				e.visible = inBool;
			}
		});
	};

	const setUiForAllExistingModels = (
		index,
		setLowerToothVisible,
		setUpperToothVisible,
		noOfModels,
	) => {
		// this function is used to set the ui in footer button for all existing models
		const totalModels = noOfModels;
		for (let i = 1; i <= totalModels; i++) {
			let mandiBularModel = threeViewer.scene.getObjectByName(
				`mandibular_${index + 1}`,
			);
			if (mandiBularModel) {
				setLowerToothVisible(true);
			}
			let maxillaryModel = threeViewer.scene.getObjectByName(
				`maxillary_${index + 1}`,
			);
			if (maxillaryModel) {
				setUpperToothVisible(true);
			}
		}
	};

	const addGridLine = () => {
		// this function is used to add grid lines by setting its visibility
		threeViewer.camera.getObjectByName("grid1").visible =
			!threeViewer.camera.getObjectByName("grid1").visible;
		threeViewer.camera.getObjectByName("grid2").visible =
			!threeViewer.camera.getObjectByName("grid2").visible;

		let threeViewerRight = threeViewer.threeViewerRight;
		threeViewerRight.camera.getObjectByName("grid1").visible =
			!threeViewerRight.camera.getObjectByName("grid1").visible;
		threeViewerRight.camera.getObjectByName("grid2").visible =
			!threeViewerRight.camera.getObjectByName("grid2").visible;
	};
	const imposeModel = () => {
		// this function is used to impose the models on the existing tooth structure
		isImposed = !isImposed;
		let maxillaryVisible =
			threeViewer.scene.getObjectByName("maxillary").visible;
		let mandibularVisible =
			threeViewer.scene.getObjectByName("mandibular").visible;
		if (
			!threeViewer.scene.getObjectByName("maxillaryClone") &&
			!threeViewer.scene.getObjectByName("mandibularClone")
		) {
			let maxillaryModel = threeViewer.scene
				.getObjectByName("maxillary_1")
				.clone();
			let mandibularModel = threeViewer.scene
				.getObjectByName("mandibular_1")
				.clone();
			maxillaryModel.name = "maxillaryClone";
			mandibularModel.name = "mandibularClone";

			maxillaryModel.traverse((e) => {
				if (!e.isMesh) {
					return;
				}
				e.geometry.computeVertexNormals();

				let material = new THREE.MeshPhongMaterial({
					transparent: true,
					opacity: 0.65,
					reflectivity: 0.5,
					side: THREE.FrontSide,
					specular: "rgb(79, 13, 13)",
					shininess: 1500,
				});

				if (e.name === "Mandibular" || e.name === "Maxillary") {
					material.color.set(0xfc8697);
				} else if (e.name.startsWith("Tooth_")) {
					material.color.set("#A020F0");
				} else {
					// Add customType to identify attachments
					e["customType"] = "attachment";
					e.visible = false;
					material.color.set("#A020F0");
				}
				e.material = material;
			});

			mandibularModel.traverse((e) => {
				if (!e.isMesh) {
					return;
				}
				e.geometry.computeVertexNormals();

				let material = new THREE.MeshPhongMaterial({
					transparent: true,
					opacity: 0.65,
					reflectivity: 0.5,
					side: THREE.FrontSide,
					specular: "rgb(79, 13, 13)",
					shininess: 1500,
				});

				if (e.name === "Mandibular" || e.name === "Maxillary") {
					material.color.set(0xfc8697);
				} else if (e.name.startsWith("Tooth_")) {
					material.color.set("#A020F0");
				} else {
					// Add customType to identify attachments
					e["customType"] = "attachment";
					e.visible = false;
					material.color.set("#A020F0");
				}
				e.material = material;
			});
			mandibularModel.visible = mandibularVisible;
			maxillaryModel.visible = maxillaryVisible;
			let cloneGroup = new THREE.Group();
			cloneGroup.add(mandibularModel);
			cloneGroup.add(maxillaryModel);
			threeViewer.scene.add(cloneGroup);
			cloneGroup.position.copy(
				threeViewer.scene.getObjectByName("maxillary_1").parent.parent.position,
			);
		} else if (isImposed) {
			if (threeViewer.scene.getObjectByName("mandibularClone")) {
				threeViewer.scene.getObjectByName("mandibularClone").visible =
					mandibularVisible;
			}
			if (threeViewer.scene.getObjectByName("maxillaryClone")) {
				threeViewer.scene.getObjectByName("maxillaryClone").visible =
					maxillaryVisible;
			}
		} else {
			if (threeViewer.scene.getObjectByName("mandibularClone")) {
				threeViewer.scene.getObjectByName("mandibularClone").visible = false;
			}
			if (threeViewer.scene.getObjectByName("maxillaryClone")) {
				threeViewer.scene.getObjectByName("maxillaryClone").visible = false;
			}
		}
	};
	const showOrHideImposedModel = () => {
		// this function is used to show or hide the imposed model
		if (isImposed) {
			threeViewer.scene.getObjectByName("mandibularClone").visible = true;
			threeViewer.scene.getObjectByName("maxillaryClone").visible = true;
		} else {
			if (threeViewer.scene.getObjectByName("mandibularClone")) {
				threeViewer.scene.getObjectByName("mandibularClone").visible = false;
			}
			if (threeViewer.scene.getObjectByName("maxillaryClone")) {
				threeViewer.scene.getObjectByName("maxillaryClone").visible = false;
			}
		}
	};

	const splitView = () => {
		// this function is used to split the viewer
		let threeViewerRight = threeViewer.threeViewerRight;
		if (threeViewerRight.params.element.style.display === "none") {
			setVisibleModel(1);
			globalState.setCurrentSetup(0);
			threeViewer.params.element.classList.add("viewerleftSplit");
			threeViewer.params.element.style.width = "50%";
			threeViewer.params.element.style.borderRight = "1px solid #fff";
			threeViewer.onWindowResize(threeViewer);
			threeViewerRight.params.element.style.display = "block";
			threeViewerRight.params.element.style.borderLeft = "1px solid #fff";
			threeViewerRight.onWindowResize(threeViewerRight);
			playPauseRef.current.style.pointerEvents = "none";
		} else {
			threeViewer.params.element.classList.remove("viewerleftSplit");
			threeViewer.params.element.style.width = "100%";
			threeViewer.params.element.style.border = "none";
			threeViewerRight.params.element.style.display = "none";
			threeViewerRight.params.element.style.border = "none";
			threeViewer.onWindowResize(threeViewer);
			threeViewerRight.onWindowResize(threeViewerRight);
			playPauseRef.current.style.pointerEvents = "auto";
		}
	};

	const setPlayPauseRef = (inRef) => {
		// this function is used to set the playPauseRef
		playPauseRef = inRef;
	};

	const iprViewer = (visibleBool) => {
		// let samplePosForIpr = [
		// 	{
		// 		step_no: 2,
		// 		location: [20, 0, 10],
		// 		jaw: "upper",
		// 		note: "sample note 01",
		// 		value: 0.2,
		// 	},
		// 	{
		// 		step_no: 2,
		// 		location: [25, -5, 5],
		// 		jaw: "lower",
		// 		note: "sample note 02",
		// 		value: 0.3,
		// 	},
		// 	{
		// 		step_no: 3,
		// 		location: [30, 5, 0],
		// 		jaw: "upper",
		// 		note: "sample note 03",
		// 		value: 0.4,
		// 	},
		// 	{
		// 		step_no: 4,
		// 		location: [30, 7, 0],
		// 		jaw: "upper",
		// 		note: "sample note 04",
		// 		value: 0.5,
		// 	},
		// ];
		if (threeViewer.scene.getObjectByName("iprGroup")) {
			if (
				globalRef.current.toothOrientation == "upper" ||
				globalRef.current.toothOrientation == "lower"
			) {
				setVisibilityAccordingToToothVisible(
					globalRef.current.currentSetup,
					visibleBool,
					globalRef.current.toothOrientation,
					false,
				);
			} else {
				setVisibilityAccordingToToothVisible(
					globalRef.current.currentSetup,
					visibleBool,
					false,
					true,
				);
			}
			// hideOrShowIprLabels(false,false,visibleBool);
		} else {
			if (globalRef.current.iprData) {
				const iprGroup = new THREE.Object3D();
				iprGroup.name = "iprGroup";
				threeViewer.scene.add(iprGroup);

				// samplePosForIpr.forEach((item) => {
				//   const direction = getDirectionFromCentroid(threeViewer.scene.getObjectByName('allToothModel'), new THREE.Vector3(...item.location));
				//   createLabelsForIpr(item, iprGroup, direction,visibleBool);
				// });
				globalRef.current.iprData.forEach((item) => {
					const direction = getDirectionFromCentroid(
						threeViewer.scene.getObjectByName("allToothModel"),
						new THREE.Vector3(...item.location),
					);
					createLabelsForIpr(item, iprGroup, direction, visibleBool);
					if (
						globalRef.current.toothOrientation == "upper" ||
						globalRef.current.toothOrientation == "lower"
					) {
						setVisibilityAccordingToToothVisible(
							globalRef.current.currentSetup,
							visibleBool,
							globalRef.current.toothOrientation,
							false,
						);
					} else {
						setVisibilityAccordingToToothVisible(
							globalRef.current.currentSetup,
							visibleBool,
							false,
							true,
						);
					}
				});
			} else {
				console.warn("IPR data is not available.");
			}
		}
	};
	const iprViewerForUploader = (visibleBool) => {
		if (threeViewer.scene.getObjectByName("iprGroup")) {
			setVisibilityAccordingToToothVisible(
				globalRef.current.currentSetup,
				visibleBool,
				false,
				true,
			);
			// hideOrShowIprLabels(false,false,visibleBool);
		} else {
			if (globalRef.current.iprData) {
				const iprGroup = new THREE.Object3D();
				iprGroup.name = "iprGroup";
				threeViewer.scene.add(iprGroup);
				globalRef.current.iprData.forEach((item) => {
					const direction = getDirectionFromCentroid(
						threeViewer.scene.getObjectByName("allToothModel"),
						new THREE.Vector3(...item.location),
					);
					createLabelsForIprForUploader(item, iprGroup, direction, visibleBool);
					setVisibilityAccordingToToothVisible(
						globalRef.current.currentSetup,
						visibleBool,
						false,
						true,
					);
				});
			} else {
				console.warn("IPR data is not available.");
			}
		}
	};

	const createLabelsForIpr = (item, parent, direction, visibleBool) => {
		const { location, value, note, jaw } = item;
		const initialPos = new THREE.Vector3(...location);
		const offset = 30;

		const group = new THREE.Group();
		group.name = jaw + item.step_no;
		group.visible = false;

		const labelDiv = createLabelElement("labelIprNo", value, group, false);
		const labelObject = createCSS2DObject(
			labelDiv,
			location,
			offset,
			direction,
		);
		labelObject.visible = false;
		labelObject.name = item.step_no;

		const popupDiv = createPopupElement(note);
		// const popupObject = createCSS2DObject(popupDiv, location, offset + 2, direction);
		labelDiv.appendChild(popupDiv);
		// popupObject.visible = false;
		// popupObject.name = 'popupIpr';

		labelObject.element.addEventListener("mouseover", () => {
			// popupObject.visible = true;
			popupDiv.style.display = "block";
		});
		labelObject.element.addEventListener("mouseout", () => {
			// popupObject.visible = false;
			popupDiv.style.display = "none";
		});

		const line = createSphereAndAddAllGeometryToItsChildren(
			initialPos.clone(),
			labelObject.position,
			jaw,
			labelObject,
		);
		line.sphere.name = item.step_no;
		group.add(line.sphere);
		labelObject.position.set(
			line.positions[3].x,
			line.positions[3].y,
			line.positions[3].z,
		);
		// popupObject.position.set(line.positions[3].x,line.positions[3].y+6 ,line.positions[3].z);

		parent.add(group);
	};
	const createLabelsForIprForUploader = (
		item,
		parent,
		direction,
		visibleBool,
	) => {
		const { location, value, note, jaw, id, between } = item;
		const initialPos = new THREE.Vector3(...location);
		const offset = 30;

		const group = new THREE.Group();
		group.name = jaw + item.step_no;
		group.visible = false;

		const labelText = `${value} (${between})`;

		const labelDiv = createLabelElement(
			"labelIprNo",
			labelText,
			group,
			true,
			id,
		);
		const labelObject = createCSS2DObject(
			labelDiv,
			location,
			offset,
			direction,
		);
		labelObject.visible = false;
		labelObject.name = item.step_no;

		const popupDiv = createPopupElement(note);
		// const popupObject = createCSS2DObject(popupDiv, location, offset + 2, direction);
		labelDiv.appendChild(popupDiv);
		// popupObject.visible = false;
		// popupObject.name = 'popupIpr';

		labelObject.element.addEventListener("mouseover", () => {
			// popupObject.visible = true;
			popupDiv.style.display = "block";
		});
		labelObject.element.addEventListener("mouseout", () => {
			// popupObject.visible = false;
			popupDiv.style.display = "none";
		});

		const line = createSphereAndAddAllGeometryToItsChildren(
			initialPos.clone(),
			labelObject.position,
			jaw,
			labelObject,
		);
		line.sphere.name = item.step_no;
		group.add(line.sphere);
		labelObject.position.set(
			line.positions[3].x,
			line.positions[3].y,
			line.positions[3].z,
		);
		// popupObject.position.set(line.positions[3].x,line.positions[3].y+6 ,line.positions[3].z);

		parent.add(group);
	};

	const createPopupElement = (note) => {
		const popupDiv = document.createElement("div");
		popupDiv.className = "popUpForIpr";
		popupDiv.textContent = note;
		popupDiv.style.zIndex = 50;
		popupDiv.style.pointerEvents = "none";
		popupDiv.style.display = "none";
		return popupDiv;
	};

	const createLabelElement = (
		className,
		text,
		labelGroup,
		uploaderBool,
		id = 0,
	) => {
		const labelDiv = document.createElement("div");
		labelDiv.className = className;
		labelDiv.textContent = text;

		// Apply container style to label div
		labelDiv.classList.add("labelIprContainer");

		if (uploaderBool) {
			// Create a button for each label
			const button = document.createElement("button");
			button.textContent = "X";
			button.className = "labelRemoveButton";

			button.addEventListener("click", () => {
				removeObjWithChildren(labelGroup);
				let iprObjectAccumulator = createAccumulator();
				let mainIprObj = iprObjectAccumulator.getMainObject();
				mainIprObj = mainIprObj.filter((obj) => obj.id !== id);
				iprObjectAccumulator.setMainObject(mainIprObj);
				mainIprObj = iprObjectAccumulator.getMainObject();
				globalRef.current.setIprJsonObj(mainIprObj);
			});

			// Append the button to the label div
			labelDiv.appendChild(button);
		}

		labelDiv.style.marginTop = "-1em";
		labelDiv.style.zIndex = 100;
		labelDiv.style.pointerEvents = "auto";
		return labelDiv;
	};

	const createCSS2DObject = (element, position, offset, direction) => {
		const label = new CSS2DObject(element);
		label.position.copy(new THREE.Vector3(...position).clone());
		label.position.addScaledVector(direction, offset);
		return label;
	};

	const createSphereAndAddAllGeometryToItsChildren = (
		startPosition,
		endPosition,
		jaw,
		labelObject,
		popupObject,
	) => {
		let percentageOfBoundingBoxToBeTaken = 0.25;
		let toothModel = threeViewer.scene.getObjectByName("allToothModel");
		let bbxForAllToothModel = new THREE.Box3().setFromObject(toothModel);
		let centerOfToothModel = new THREE.Vector3();
		bbxForAllToothModel.getCenter(centerOfToothModel);
		centerOfToothModel.z = bbxForAllToothModel.max.z;
		let totalLengthOfXAccToPercent =
			(bbxForAllToothModel.max.x - bbxForAllToothModel.min.x) *
			percentageOfBoundingBoxToBeTaken;
		const geometry = new THREE.SphereGeometry(1, 32, 32);
		const material = new THREE.MeshBasicMaterial({
			color: new THREE.Color("#2d5d9f"),
		});
		const sphere = new THREE.Mesh(geometry, material);
		sphere.position.copy(startPosition);
		let positions = [
			new THREE.Vector3(0, 0, 0),
			new THREE.Vector3(10, 0, 0),
			new THREE.Vector3(20, 0, 0),
			new THREE.Vector3(30, 20, 0),
		];
		if (startPosition.x < 0) {
			positions = [
				new THREE.Vector3(0, 0, 0),
				new THREE.Vector3(-10, 0, 0),
				new THREE.Vector3(-20, 0, 0),
				new THREE.Vector3(-30, 20, 0),
			];
		}
		if (
			startPosition.x <= centerOfToothModel.x + totalLengthOfXAccToPercent &&
			startPosition.x >= centerOfToothModel.x - totalLengthOfXAccToPercent
		) {
			// If startPosition is inside the 25% volume of the bounding box
			positions = [
				new THREE.Vector3(0, 0, 0),
				new THREE.Vector3(0, 0, 10),
				new THREE.Vector3(0, 0, 20),
				new THREE.Vector3(0, 20, 30),
			];
		}
		if (jaw === "lower") {
			positions[3].y = -positions[3].y;
		}
		const curve = new THREE.CatmullRomCurve3(positions, false, "chordal");

		let tubeGeometry = new THREE.TubeGeometry(curve, 100, 0.05, 10, false);
		let tubeMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
		let tubeMesh = new THREE.Mesh(tubeGeometry, tubeMaterial);
		sphere.add(tubeMesh);
		sphere.add(labelObject);
		sphere.add(popupObject);

		return { sphere, positions };
	};

	const getDirectionFromCentroid = (object3d, position) => {
		const boundingBox = new THREE.Box3().setFromObject(object3d);
		let center = new THREE.Vector3();
		boundingBox.getCenter(center);
		center.z = center.z + (boundingBox.min.z + center.z) / 2;
		const subVectors = position.clone().sub(center);
		return subVectors.normalize();
	};
	const setVisibilityAccordingToToothVisible = (
		toothVisible,
		showHideBool,
		toothSegment,
		showAllToothBool,
	) => {
		let allIprLabels = threeViewer.scene.getObjectByName("iprGroup");
		if (showAllToothBool) {
			if (allIprLabels) {
				allIprLabels.children.forEach((labelGroup) => {
					if (
						labelGroup.name === "upper" + toothVisible ||
						labelGroup.name === "lower" + toothVisible
					) {
						labelGroup.visible = showHideBool;
						labelGroup.children.forEach((labelObject) => {
							// eslint-disable-next-line
							if (
								labelObject.name !== "popupIpr" &&
								labelObject.name === toothVisible
							) {
								labelObject.visible = showHideBool;
								if (labelObject.children.length > 0) {
									labelObject.children.forEach((mesh) => {
										if (mesh.name !== "popupIpr") {
											mesh.visible = showHideBool;
										}
									});
								}
							}
						});
					}
				});
			}
		}
		if (toothSegment) {
			allIprLabels.children.forEach((labelGroup) => {
				// eslint-disable-next-line
				if (
					labelGroup.name === "upper" + toothVisible ||
					labelGroup.name === "lower" + toothVisible
				) {
					labelGroup.visible = false;
					labelGroup.children.forEach((labelObject) => {
						// eslint-disable-next-line
						if (
							labelObject.name !== "popupIpr" &&
							labelObject.name === toothVisible
						) {
							labelObject.visible = false;
							if (labelObject.children.length > 0) {
								labelObject.children.forEach((mesh) => {
									mesh.visible = false;
								});
							}
						}
					});
				}
			});
			allIprLabels.children.forEach((labelGroup) => {
				// eslint-disable-next-line
				if (labelGroup.name == toothSegment + toothVisible) {
					labelGroup.visible = showHideBool;
					labelGroup.children.forEach((labelObject) => {
						// eslint-disable-next-line
						if (
							labelObject.name !== "popupIpr" &&
							labelObject.name === toothVisible
						) {
							labelObject.visible = showHideBool;
							if (labelObject.children.length > 0) {
								labelObject.children.forEach((mesh) => {
									if (mesh.name !== "popupIpr") {
										mesh.visible = showHideBool;
									}
								});
							}
						}
					});
				}
			});
		}
	};
	const hideAllIprLabels = () => {
		const allIprLabels = threeViewer.scene.getObjectByName("iprGroup");
		if (allIprLabels) {
			allIprLabels.children.forEach((labelGroup) => {
				labelGroup.children.forEach((labelObject) => {
					labelObject.visible = false;
					if (labelObject.children.length > 0) {
						labelObject.children.forEach((mesh) => {
							mesh.visible = false;
						});
					}
				});
			});
		}
	};
	const activateAddIpr = (globalState) => {
		globalState.setIprOpen(true);
		globalState.setIprJawValue("");
		// globalState.spanRefForToothSelectionInIpr.current.style.display = 'flex'
		// globalState.addIprButtonHeaderRef.current.attribute("disabled");
		globalState.addIprButtonHeaderRef.current.setAttribute("disabled", "true");
		// globalState.addIprButtonHeaderRef.current.style.color = 'white'
		threeViewer.addIprDataBool = true;
		threeViewer.globalState = globalState;
	};
	const generateJsonData = (globalState) => {
		let mainJsonArr = globalState.iprJsonObj;
		downloadJSONData(mainJsonArr);
	};
	const disposeThreeViewer = (viewer) => {
		if (viewer.scene) {
			while (viewer.scene.children.length > 0) {
				viewer.scene.remove(viewer.scene.children[0]);
			}
		}
		if (viewer.renderer) {
			viewer.renderer.dispose();
			viewer.element.removeChild(viewer.renderer.domElement);
			viewer.element.innerHtml = "";
		}
	};

	const removeObjWithChildren = (obj) => {
		if (obj.children.length > 0) {
			for (var x = obj.children.length - 1; x >= 0; x--) {
				removeObjWithChildren(obj.children[x]);
			}
		}
		if (obj.isMesh) {
			obj.geometry.dispose();
			obj.material.dispose();
		}
		if (obj.parent) {
			obj.parent.remove(obj);
		}
	};
	return {
		setTopView,
		setLeftView,
		setFrontView,
		setRightView,
		setBottomView,
		hideMaxillaryGroup,
		playAnimation,
		hideMandibularGroup,
		setVisibleModel,
		toggleAttachment,
		setUiForAllExistingModels,
		addGridLine,
		imposeModel,
		splitView,
		setPlayPauseRef,
		iprViewer,
		showOrHideImposedModel,
		hideAllIprLabels,
		setVisibilityAccordingToToothVisible,
		activateAddIpr,
		generateJsonData,
		disposeThreeViewer,
		removeObjWithChildren,
		iprViewerForUploader,
	};
}
