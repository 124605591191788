import { useEffect, useState, useRef } from 'react';
import StateContext from '../../context/stateGlobal/StateContext';
import { useContext } from 'react';

export default function FooterModelButton({ index }) {

    const globalState = useContext(StateContext)
    const handleBtnClick = (event) => {
        globalState.actions.setVisibleModel(Number(event.target.textContent) + 1,globalState.subSetGroup)
        globalState.setCurrentSetup(Number(event.target.textContent))
        if(globalState.iprBool){
            globalState.actions.hideAllIprLabels()
            if(globalState.toothOrientation == 'upper' || globalState.toothOrientation == 'lower'){
                globalState.actions.setVisibilityAccordingToToothVisible(Number(event.target.textContent),true,globalState.toothOrientation,false)
            }else{
                globalState.actions.setVisibilityAccordingToToothVisible(Number(event.target.textContent),true,false,true)
            }
        }

    }
    const [active, setActive] = useState(false)
    const [upperToothVisible , setUpperToothVisible] = useState(false)
    const [lowerToothVisible , setLowerToothVisible] = useState(false)
    useEffect(() => {
        if (index == globalState.currentSetup) {
            if (globalState.iprBool) {
                globalState.actions.hideAllIprLabels()
                if(globalState.toothOrientation == 'upper' || globalState.toothOrientation == 'lower'){
                    globalState.actions.setVisibilityAccordingToToothVisible(index,true,globalState.toothOrientation,false)
                }else{
                    globalState.actions.setVisibilityAccordingToToothVisible(index,true,false,true)
                }
            }
            setActive(true)
        }
        else {
            setActive(false)
        }
    }, [globalState.currentSetup]);
    useEffect(() => {
        if(globalState.actions){
            globalState.actions.setUiForAllExistingModels(index , setLowerToothVisible ,setUpperToothVisible,globalState.subSetGroup)
        }
    },[globalState.subSetGroup]);
    return (
        <div>
            <button className={`CountBtn-number ${active ? 'active' : ''}`} key={index} onClick={handleBtnClick}>
                {index}
                {/* <div  className='counterbtn-line'> */}
                    <span className={`line line-top ${upperToothVisible ? 'active' : ''}`} key={`Upper-${index}`}></span>
                    <span className={`line line-bottom ${lowerToothVisible ? 'active' : ''}`} key={`Lower-${index}`}></span>
                {/* </div> */}
            </button>            
        </div>
    )
    
}
