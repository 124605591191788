import React from "react";
import "../Content/Content.css";
import Product3D from "../Product3D/Product3D";

export default function Content({ isAddIpr }) {
	return (
		<div className="Content-Section">
			<Product3D isAddIpr={isAddIpr} />
		</div>
	);
}
