import React, { useContext, useRef } from "react";
import HeaderNav from "../Header/HeaderNav";
import PatientContent from "../Patient/PatientContent";
import stateContext from "../../context/stateGlobal/StateContext";
import Login from "../Header/Login";
import "../Home/Home.css";

export default function Home() {
	const appDivRef = useRef(null);
	const globalState = useContext(stateContext);

	return (
		<div
			className={`App ${globalState.isLoggedIn ? "loggedIn" : ""}`}
			ref={appDivRef}
		>
			{globalState.isLoggedIn ? (
				<>
					<HeaderNav appDivRef={appDivRef} noMenu={false} type="patientList"/>
					<PatientContent />
				</>
			) : (
				<>
					<Login appDivRef={appDivRef} />
				</>
			)}
		</div>
	);
}
