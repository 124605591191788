import react from "react";
import stateContext from "./StateContext";
import { checkLogin } from "../../Components/CommonFunction/checkLogin";
const userData = checkLogin();

const GlobalState = (props) => {
	const [sections, setSections] = react.useState([]);
	const [uiState, setUiState] = react.useState({});
	const [actions, setActions] = react.useState(null);
	const [loggedInUserId, setLoggedUserId] = react.useState(null);
	const [basePrice, setBasePrice] = react.useState(0);
	const [productInfo, setProductInfo] = react.useState(null);
	const [subSetGroup, setSubsetGroup] = react.useState(0);
	const [viewer, setViewer] = react.useState({});
	// const [loggedUserName, setLoggedUserName] = react.useState(
	// 	userData ? userData.userName : "Unknown"
	// );

	const [nameOfUser, setNameOfUser] = react.useState(
		userData ? userData.name : "Unknown"
	);

	const [initialized, setInitialized] = react.useState(false);
	const [currentSetup, setCurrentSetup] = react.useState(null);
	const [treatmentData, setTreatmentData] = react.useState(null);
	const [tempTreatmentData, setTempTreatmentData] = react.useState(null);
	const [selectedTreatmentPlan, setSelectedTreatmentPlan] =
		react.useState(null);
	const [iprBool, setIprBool] = react.useState(true);
	const [toothOrientation, setToothOrientation] = react.useState("front");
	const [iprData, setIprData] = react.useState(null);
	const [addingIprDataBool, setAddingIprDataBool] = react.useState(false);
	const [isFileDropped, setIsFileDropped] = react.useState(false);
	const [isIprOpen, setIprOpen] = react.useState(false);
	const [iprJawValue, setIprJawValue] = react.useState("toothOrientation");
	const [iprLocationValue, setIprLocationValue] = react.useState([]);
	const [iprJsonObj, setIprJsonObj] = react.useState([]);
	const [isLoggedInForIpr, setIsLoggedInForIpr] = react.useState(false);
	const [isLoggedIn, setIsLoggedIn] = react.useState(userData ? true : false);
	const footerRef = react.useRef();
	const spanRefForToothSelectionInIpr = react.useRef();
	const addIprButtonHeaderRef = react.useRef();
	const playPauseRef = react.useRef();
	const iprJawRef = react.useRef();
	const iprNoteRef = react.useRef();
	const iprValueRef = react.useRef();
	const iprBetweenRef = react.useRef();
	const dragAndDropRef = react.useRef();
	const loadingDivRef = react.useRef();
	const threeViewerRight = {};

	return (
		<stateContext.Provider
			value={{
				sections,
				setSections,
				actions,
				setActions,
				uiState,
				setUiState,
				basePrice,
				setBasePrice,
				productInfo,
				setProductInfo,
				initialized,
				setInitialized,
				subSetGroup,
				setSubsetGroup,
				currentSetup,
				setCurrentSetup,
				treatmentData,
				setTreatmentData,
				selectedTreatmentPlan,
				setSelectedTreatmentPlan,
				threeViewerRight,
				tempTreatmentData,
				setTempTreatmentData,
				iprBool,
				setIprBool,
				toothOrientation,
				setToothOrientation,
				iprData,
				setIprData,
				addingIprDataBool,
				setAddingIprDataBool,
				dragAndDropRef,
				isFileDropped,
				setIsFileDropped,
				viewer,
				setViewer,
				loadingDivRef,
				iprJawRef,
				iprNoteRef,
				iprValueRef,
				iprBetweenRef,
				isIprOpen,
				setIprOpen,
				iprJawValue,
				setIprJawValue,
				iprLocationValue,
				setIprLocationValue,
				iprJsonObj,
				setIprJsonObj,
				playPauseRef,
				isLoggedInForIpr,
				setIsLoggedInForIpr,
				footerRef,
				spanRefForToothSelectionInIpr,
				addIprButtonHeaderRef,
				nameOfUser,
				setNameOfUser,
				isLoggedIn,
				setIsLoggedIn,
				loggedInUserId,
				setLoggedUserId
				
			}}
		>
			{props.children}
		</stateContext.Provider>
	);
};

export default GlobalState;
