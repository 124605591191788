import React from "react";
import {
	MaterialReactTable,
	useMaterialReactTable,
} from "material-react-table";

const PatientList = ({ data, isLoading, isRefetching, rowCount, pagination, setPagination, globalFilter, setGlobalFilter, columnFilter, setColumnFilter, columnSort, setColumnSort, userRole, density, setDensity }) => {

	const viewerColumns = [
		{
			accessorKey: "attributes.patient_name",
			header: "Patient Name",
			size: 150,
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.patient_name !== null ? renderedCellValue : 'N/A'}
				</span>
			),
		},
		{
			accessorKey: "attributes.patient_id",
			header: "Patient ID",
			size: 200,
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.patient_id !== null ? renderedCellValue : 'N/A'}
				</span>
			),
		},
		{
			accessorKey: "attributes.treatment_plan_version",
			header: "Version",
			size: 100,
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.patient_id !== null ? renderedCellValue : 'N/A'}
				</span>
			),
		},
		{
			accessorKey: "attributes.link",
			header: "Link",
			size: 100,
			Cell: ({ row }) => (
				<span>
					{row.original.attributes.link !== null ? <button
						onClick={() => window.open(row.original.attributes.link, '_blank')}
						style={{ cursor: 'pointer' }}
					>
						View
					</button> : 'N/A'}
				</span>

			),
		},
		{
			accessorKey: "attributes.upper_steps_number",
			header: "Upper",
			muiTableBodyCellProps: {
				align: 'center',
			},
			size: 100,
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.upper_steps_number !== null ? <span>{renderedCellValue}</span> : 'N/A'}
				</span>
			),
		},
		{
			accessorKey: "attributes.lower_steps_number",
			header: "Lower",
			size: 100,
			muiTableBodyCellProps: {
				align: 'center',
			},
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.lower_steps_number !== null ? <span>{renderedCellValue}</span> : 'N/A'}
				</span>
			),
		},
		{
			accessorKey: "attributes.status",
			header: "Status",
			size: 150,
			Cell: ({ renderedCellValue, row }) => (
				<span>
					{row.original.attributes.status !== null ? <span>{renderedCellValue}</span> : 'N/A'}
				</span>
			),
		},
	]

	const doctorColumn = {
		accessorKey: "attributes.doctor_name",
		header: "Doctor Name",
		size: 150,
		Cell: ({ renderedCellValue, row }) => (
			<span>
				{row.original.attributes.patient_name !== null ? renderedCellValue : 'N/A'}
			</span>
		),
	}

	const columns = React.useMemo(
		() => {
			if (userRole === "uploader" || userRole === "admin") {
				viewerColumns.splice(1, 0, doctorColumn);
			}
			return viewerColumns
		},
		[]
	);

	const table = useMaterialReactTable({
		columns,
		data,
		enableGrouping: true,
		manualPagination: true,
		onPaginationChange: setPagination,
		rowCount,
		isMultiSortEvent: () => true,
		state: {
			isLoading,
			showProgressBars: isRefetching,
			pagination,
			globalFilter,
			columnFilter,
			sorting: columnSort,
			density: density,
		},
		onDensityChange: setDensity,
		manualSorting: true,
		manualFiltering: true,
		onSortingChange: setColumnSort,
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilter,
	});

	return (
		<>
			<h3 className="viewer__title text-center">Patients List</h3>
			<div className="table-responsive viewer__table">
				<MaterialReactTable table={table} />
			</div>
		</>
	);
};

export default PatientList;
