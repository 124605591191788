import React, { useState, useContext, useRef, useEffect } from "react";
import "../Header/HeaderNav.css";
import StateContext from "../../context/stateGlobal/StateContext";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function HeaderNavMenu({
	toggleBtnMenu,
	setToggleBtnMenu,
	appDivRef,type
}) {
	let publicUrl = process.env.PUBLIC_URL;
	const [activeButton, setActiveButton] = useState(4);
	const globalState = useContext(StateContext);
	const hideMandibularRef = useRef();
	const hideMaxillaryRef = useRef();
	const [attachmentVisibleBool, setAttachmentVisibleBool] = useState(true);
	const [splitBool, setSplitBool] = useState(false);
	const [gridBool, setGridBool] = useState(false);
	const [superImposeBool, setSuperImposeBool] = useState(false);
	const [userName, setUserName] = useState("");
	// const [isActive, setIsActive] = useState(false);

	const handleButtonClick = (buttonId) => {
		setActiveButton(buttonId);
		setActiveButton(buttonId);
		if (buttonId === 1) {
			globalState.actions.hideMandibularGroup();
		}
		if (buttonId === 2) {
			globalState.actions.setBottomView();
			hideMaxAndMand(true);
		}
		if (buttonId === 3) {
			globalState.actions.setLeftView();
			hideMaxAndMand(false);
		}
		if (buttonId === 4) {
			globalState.actions.setFrontView();
			hideMaxAndMand(false);
		}
		if (buttonId === 5) {
			globalState.actions.setRightView();
			hideMaxAndMand(false);
		}
		if (buttonId === 6) {
			globalState.actions.setTopView();
			hideMaxAndMand(true);
		}
		if (buttonId === 7) {
			globalState.actions.hideMaxillaryGroup();
		}
		if (buttonId === 9) {
			// globalState.actions.iprViewer()
		}
		if (buttonId === 13) {
			globalState.actions.activateAddIpr();
		}
	};

	const toggleAttachment = () => {
		setAttachmentVisibleBool(!attachmentVisibleBool);
	};

	const toggleAttachmentRef = useRef(toggleAttachment);

	useEffect(() => {
		if (attachmentVisibleBool) {
			toggleAttachmentRef.current.classList.add("active");
			globalState.actions?.toggleAttachment(true);
		} else {
			toggleAttachmentRef.current.classList.remove("active");
			globalState.actions?.toggleAttachment(false);
		}
	}, [attachmentVisibleBool]);

	useEffect(() => {
		let jwtToken = cookies.get("jwtToken", { path: "/" });
		let userName = cookies.get("userName", { path: "/" });
		if (jwtToken) {
			if (userName) {
				setUserName(userName);
			}
		} else {
			setUserName("");
		}
	}, [globalState.tempTreatmentData]);

	const toggleSplitView = () => {
		setSplitBool(!splitBool);
		globalState.actions.splitView(globalState.threeViewerRight);
	};

	const toggleSplitViewRef = useRef(toggleSplitView);

	useEffect(() => {
		if (splitBool) {
			toggleSplitViewRef.current.classList.add("active");
			// globalState.actions?.toggleSplitView(true)
		} else {
			toggleSplitViewRef.current.classList.remove("active");
			// globalState.actions?.toggleSplitView(false)
		}
	}, [splitBool]);

	const toggleGridView = () => {
		setGridBool(!gridBool);
		globalState.actions.addGridLine();
	};
	const gridRef = useRef(toggleGridView);

	useEffect(() => {
		if (gridBool) {
			gridRef.current.classList.add("active");
			// globalState.actions.addGridLine()
		} else {
			gridRef.current.classList.remove("active");
		}
	});

	const toggleIprViewer = () => {
		globalState.setIprBool(!globalState.iprBool);
		globalState.actions.iprViewer(!globalState.iprBool);
	};
	const iprRef = useRef(toggleIprViewer);

	useEffect(() => {
		if (globalState.iprBool) {
			iprRef.current.classList.add("active");
		} else {
			iprRef.current.classList.remove("active");
		}
	});

	const toggleSuperImpose = () => {
		setSuperImposeBool(!superImposeBool);
		globalState.actions.imposeModel();
	};
	const superImposeRef = useRef(toggleSuperImpose);
	useEffect(() => {
		if (superImposeBool) {
			superImposeRef.current.classList.add("active");
		} else {
			superImposeRef.current.classList.remove("active");
		}
	});

	function hideMaxAndMand(inBool) {
		if (inBool) {
			hideMandibularRef.current.style.pointerEvents = "none";
			hideMaxillaryRef.current.style.pointerEvents = "none";
		} else {
			hideMandibularRef.current.style.pointerEvents = "auto";
			hideMaxillaryRef.current.style.pointerEvents = "auto";
		}
	}

	const buttonMenu = React.useRef(null);
	React.useEffect(() => {
		if (toggleBtnMenu) {
			buttonMenu.current.style.left = "0px";
		} else {
			buttonMenu.current.style.left = "-250px";
		}
	}, []);

	useEffect(() => {
		document.addEventListener('click', setToggleBtnMenu(true));
		return () => {
		  document.removeEventListener('click', setToggleBtnMenu(true));
		};
	  }, []);

	  const handleClickOutside = (event) => {
		if(toggleBtnMenu === true)
		  setToggleBtnMenu(false);
	  };

	  useEffect(() => {
		const handleEscapeKey = (event) => {
		  if (event.key === 'Escape') {
			setToggleBtnMenu(false);
		  }
		};
	
		const handleOutsideClick = (event) => {
		  handleClickOutside(event);
		};
	
		document.addEventListener('keydown', handleEscapeKey);
		document.addEventListener('mousedown', handleOutsideClick);
	
		return () => {
		  document.removeEventListener('keydown', handleEscapeKey);
		  document.removeEventListener('mousedown', handleOutsideClick);
		};
	  }, [toggleBtnMenu]);

	React.useEffect(() => {
		if (toggleBtnMenu) {
			buttonMenu.current.style.left = "0px";
		} else {
			buttonMenu.current.style.left = "-250px";
			appDivRef.current.classList.remove("overlay");
		}
	}, [toggleBtnMenu]);

	window.onorientationChange = (event) => {


	};

	// const handleClick = () => {
	//   setIsActive(!isActive);
	// };

	return (
		<>
			<div className="HeaderNav-Menu" ref={buttonMenu}>
				<div className="HeaderNav-Menu-List">
					<div className="HeaderNav-Menu-Outer">
						<div className="Profile-btn">
							<button
								className="close-btn"
								onClick={() => {
									setToggleBtnMenu(false);
									appDivRef.current.classList.remove(
										"overlay"
									);
								}}
							>
								<i className="fa-solid fa-arrow-left"></i>
							</button>
							<div className="HeaderNav-Profile-Btn Profile-mobile-view">
								{/* <img src={`${publicUrl}/assets/img/Profile.svg`} className='Profile-Img' alt='Profile' /> */}
								<span className="Profile-Text">{userName}</span>
							</div>
						</div>
						<div
							className={`HeaderNav-Menu-Inner ${type === "patientList" ? "headerMain" : ""}`}
							style={{
								display: globalState?.addingIprDataBool
									? "none"
									: "flex",
							}}
						>
							<button
								className={
									activeButton === 1
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(1)}
								ref={hideMandibularRef}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.717879 13.6071C1.43258 14.8606 2.51643 15.2456 3.18229 15.2456H23.7679C24.9471 15.2456 25.7229 14.5681 26.2181 13.6766C27.2099 11.891 25.6646 9.93193 23.6666 9.50799C15.745 7.82717 8.0695 8.53061 3.2525 9.52393C1.28895 9.92884 -0.275156 11.8654 0.717879 13.6071Z"
										fill="#FBABA5"
									/>
									<path
										d="M17.4246 18.55C16.5165 18.6748 15.7132 18.6724 14.8435 18.553C13.7982 18.4096 13.1377 17.3518 13.343 16.27L13.731 14.2263C13.8882 13.3977 14.5171 12.7319 15.3258 12.6155C15.9137 12.5309 16.3299 12.5355 16.9149 12.6212C17.7393 12.742 18.3848 13.4133 18.5441 14.2525L18.9267 16.2686C19.132 17.3505 18.4709 18.4062 17.4246 18.55Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.411429"
									/>
									<path
										d="M22.1998 17.5154C21.7108 17.5628 21.2513 17.5626 20.7774 17.5175C19.7419 17.4191 19.0797 16.3588 19.2841 15.2739L19.4927 14.1669C19.65 13.3323 20.2776 12.6526 21.0778 12.574C21.3553 12.5467 21.5963 12.5482 21.8725 12.577C22.6889 12.6619 23.3325 13.3481 23.4918 14.1933L23.6952 15.2725C23.8996 16.3572 23.2367 17.4151 22.1998 17.5154Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.411429"
									/>
									<path
										d="M5.82138 17.5154C5.33248 17.5628 4.87296 17.5626 4.39902 17.5175C3.36356 17.4191 2.70129 16.3588 2.90574 15.2739L3.11435 14.1669C3.27163 13.3323 3.89926 12.6526 4.6994 12.574C4.97693 12.5467 5.21793 12.5482 5.49414 12.577C6.31052 12.6619 6.95416 13.3481 7.11344 14.1933L7.31682 15.2725C7.52123 16.3572 6.85828 17.4151 5.82138 17.5154Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.411429"
									/>
									<path
										d="M11.7561 18.55C10.848 18.6747 10.0446 18.6724 9.17494 18.553C8.12965 18.4096 7.46919 17.3517 7.67451 16.27L8.06238 14.2263C8.21968 13.3978 8.84855 12.7319 9.65731 12.6155C10.2452 12.5309 10.6614 12.5355 11.2464 12.6212C12.0707 12.742 12.7163 13.4133 12.8755 14.2525L13.2582 16.2686C13.4635 17.3504 12.8024 18.4062 11.7561 18.55Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.411429"
									/>
								</svg>
								<span className="Menu-List-Text">Upper</span>
							</button>
							<button
								className={
									activeButton === 2
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(2)}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_402_1039)">
										<path
											d="M0 18.8596C1.06719 8.01694 9.19566 5.02169 13.1265 4.87939C17.1996 4.9171 25.6767 7.76592 27 18.8596H23.1047C22.002 15.6737 18.4624 9.33388 13.1265 9.46195C10.3874 10.0709 4.78103 12.8031 4.26878 18.8596H0Z"
											fill="#FBABA5"
										/>
										<path
											d="M11.6198 7.06904C10.693 7.25101 9.88909 7.51916 9.03528 7.92043C8.0274 8.3941 7.53532 9.69122 7.91863 10.7158L8.50569 12.2854C8.79058 13.0469 9.49659 13.4894 10.2854 13.3447C10.942 13.2242 11.375 13.0757 12.0014 12.771C12.7703 12.397 13.298 11.5421 13.3405 10.6622L13.426 8.89644C13.4826 7.72631 12.6685 6.86312 11.6198 7.06904Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M5.89889 10.6582C5.38392 11.0324 4.93773 11.4395 4.49551 11.9324C3.76536 12.7462 3.67652 14.1814 4.24456 15.0478L4.98035 16.17C5.41381 16.8311 6.13458 17.0265 6.76723 16.5736C7.08857 16.3436 7.32794 16.1223 7.60991 15.8012C8.17871 15.1535 8.40096 14.0926 8.20178 13.205L7.87431 11.7457C7.61655 10.597 6.71952 10.062 5.89889 10.6582Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M2.97029 16.6243C2.83275 16.9096 2.71257 17.1977 2.60398 17.5008C2.22667 18.554 2.78002 19.976 3.71306 20.5119L3.8248 20.5761C4.50396 20.9661 5.23546 20.8114 5.58878 20.1102C5.69574 19.8978 5.77834 19.6966 5.85715 19.4595C6.12301 18.6595 5.89226 17.5994 5.34953 16.86L5.27648 16.7605C4.53901 15.7558 3.43862 15.6531 2.97029 16.6243Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M15.3286 7.06904C16.2554 7.25101 17.0593 7.51916 17.9131 7.92043C18.921 8.3941 19.4131 9.69122 19.0298 10.7158L18.4427 12.2854C18.1578 13.0469 17.4518 13.4894 16.663 13.3447C16.0064 13.2242 15.5734 13.0757 14.947 12.771C14.1781 12.397 13.6504 11.5421 13.6079 10.6622L13.5224 8.89644C13.4658 7.72631 14.2799 6.86312 15.3286 7.06904Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M21.0495 10.6582C21.5645 11.0323 22.0107 11.4395 22.4529 11.9324C23.183 12.7463 23.2719 14.1814 22.7038 15.0478L21.968 16.17C21.5346 16.8311 20.8138 17.0265 20.1812 16.5736C19.8599 16.3436 19.6205 16.1223 19.3385 15.8012C18.7697 15.1535 18.5474 14.0926 18.7466 13.205L19.0741 11.7456C19.3318 10.597 20.2289 10.062 21.0495 10.6582Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M23.9781 16.6243C24.1157 16.9096 24.2358 17.1977 24.3444 17.5008C24.7217 18.554 24.1684 19.976 23.2353 20.5119L23.1236 20.5761C22.4445 20.9661 21.7129 20.8114 21.3596 20.1102C21.2527 19.8978 21.1701 19.6966 21.0912 19.4595C20.8254 18.6595 21.0561 17.5994 21.5989 16.86L21.672 16.7605C22.4094 15.7558 23.5098 15.6531 23.9781 16.6243Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
									</g>
									<defs>
										<clipPath id="clip0_402_1039">
											<rect
												width="27"
												height="27"
												fill="white"
											/>
										</clipPath>
									</defs>
								</svg>
								<span className="Menu-List-Text">Top</span>
							</button>
							<button
								className={
									activeButton === 3
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(3)}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.87373 5.27748C18.3346 3.74741 20.4839 6.1681 20.2806 7.76247V18.4607C20.2806 18.9144 20.2417 19.3783 20.0423 19.7858C18.5511 22.833 12.9496 22.4982 8.43379 21.5182C6.23078 21.0401 4.7901 19.0092 4.7901 16.7549V10.2455C4.7901 7.81187 6.4713 5.66601 8.87373 5.27748Z"
										fill="#FBABA5"
									/>
									<path
										d="M21.5297 11.0315C21.7487 12.2425 21.0142 13.4203 19.8616 13.5375C19.3147 13.5932 18.8012 13.5929 18.2711 13.54C17.1202 13.4249 16.3864 12.2444 16.6055 11.0331L16.8625 9.61183C17.0332 8.66799 17.7345 7.89341 18.6339 7.80547C18.9239 7.77711 19.1789 7.7787 19.4675 7.80864C20.3859 7.9039 21.1057 8.68646 21.2785 9.64275L21.5297 11.0315Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M11.5022 11.1248C11.7208 12.3421 10.9803 13.5226 9.84513 13.5732C9.66449 13.5812 9.48655 13.5814 9.30844 13.5741C8.17551 13.5274 7.4354 12.3443 7.65407 11.1263L7.74734 10.607C7.9192 9.6498 8.62148 8.85303 9.49892 8.83831C9.54038 8.83762 9.58175 8.83779 9.62321 8.83875C10.52 8.85965 11.2407 9.66826 11.4148 10.6381L11.5022 11.1248Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M16.3278 11.0807C16.5496 12.2984 15.8085 13.4824 14.6601 13.5622C14.336 13.5848 14.0222 13.585 13.7051 13.5638C12.5587 13.4871 11.8182 12.3005 12.04 11.0822L12.1923 10.2458C12.3653 9.29561 13.0685 8.51035 13.9583 8.46062C14.0996 8.45272 14.235 8.45331 14.3757 8.46212C15.2847 8.51903 16.0062 9.31399 16.1815 10.2766L16.3278 11.0807Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M21.5297 16.0758C21.7487 14.8648 21.0142 13.687 19.8616 13.5698C19.3147 13.5142 18.8012 13.5143 18.2711 13.5674C17.1202 13.6823 16.3864 14.8629 16.6055 16.0742L16.8625 17.4955C17.0332 18.4393 17.7345 19.2139 18.6339 19.3018C18.9239 19.3302 19.1789 19.3286 19.4675 19.2987C20.3859 19.2034 21.1057 18.4208 21.2785 17.4646L21.5297 16.0758Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M11.5022 15.9819C11.7208 14.7647 10.9803 13.5841 9.84513 13.5336C9.66449 13.5255 9.48655 13.5253 9.30844 13.5327C8.17551 13.5793 7.4354 14.7625 7.65407 15.9804L7.74734 16.4998C7.9192 17.4569 8.62148 18.2537 9.49892 18.2684C9.54038 18.2691 9.58175 18.269 9.62321 18.2681C10.52 18.2471 11.2407 17.4385 11.4148 16.4687L11.5022 15.9819Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M16.328 16.0265C16.5498 14.8088 15.8086 13.6249 14.6602 13.545C14.3361 13.5225 14.0223 13.5223 13.7052 13.5434C12.5588 13.6201 11.8183 14.8067 12.0402 16.025L12.1924 16.8614C12.3655 17.8117 13.0686 18.5969 13.9585 18.6466C14.0997 18.6545 14.2351 18.6539 14.3759 18.6451C15.2849 18.5882 16.0064 17.7933 16.1816 16.8307L16.328 16.0265Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
								</svg>
								<span className="Menu-List-Text">Right</span>
							</button>
							<button
								className={
									activeButton === 4
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(4)}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M3.52817 10.2453C4.02216 10.6743 4.56345 10.8243 4.94193 10.8243H22.0158C22.5878 10.8243 23.0453 10.6322 23.4089 10.3247C25.1695 8.83538 23.1886 6.28027 20.9201 5.86588C15.2303 4.82656 9.77917 5.21483 5.99108 5.88871C3.76251 6.28515 1.81916 8.76109 3.52817 10.2453Z"
										fill="#FBABA5"
									/>
									<path
										d="M16.1249 13.5585C15.8259 13.577 15.5351 13.5772 15.2421 13.56C14.0783 13.4913 13.3232 12.2878 13.5572 11.0553L13.6734 10.4426C13.8536 9.4936 14.5639 8.71478 15.457 8.66676C15.6001 8.65906 15.7372 8.65966 15.8798 8.6683C16.7914 8.72349 17.5195 9.51128 17.7019 10.4721L17.8123 11.0538C18.0461 12.2858 17.2906 13.4866 16.1249 13.5585Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M20.2037 12.6663C20.1567 12.667 20.1098 12.6672 20.0629 12.6667C19.5075 12.6618 19.0601 12.3751 18.7714 11.9368C18.4803 11.4946 18.3568 10.904 18.4658 10.3255L18.4738 10.2831C18.6418 9.39162 19.3062 8.65782 20.1143 8.66139C20.9383 8.66502 21.6157 9.41827 21.7864 10.3236C21.8945 10.8976 21.7727 11.4871 21.4839 11.9301C21.1976 12.3695 20.7539 12.658 20.2037 12.6663Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M6.61934 12.6663C6.57226 12.667 6.52532 12.6672 6.47844 12.6667C5.92309 12.6618 5.47564 12.3751 5.18701 11.9368C4.89583 11.4946 4.77236 10.904 4.88138 10.3255L4.88938 10.2831C5.05736 9.39162 5.72176 8.65782 6.52986 8.66139C7.3539 8.66502 8.03132 9.41827 8.20193 10.3236C8.3101 10.8976 8.18827 11.4871 7.8995 11.9301C7.61316 12.3695 7.16949 12.658 6.61934 12.6663Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M11.4234 13.559C11.1244 13.5775 10.8337 13.5777 10.5406 13.5605C9.37694 13.4918 8.62177 12.2883 8.85567 11.0558L8.97203 10.4431C9.15215 9.4941 9.86242 8.71528 10.7556 8.66725C10.8987 8.65955 11.0357 8.66015 11.1784 8.66878C12.09 8.72399 12.8181 9.51178 13.0004 10.4726L13.1109 11.0543C13.3447 12.2863 12.5891 13.4871 11.4234 13.559Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M18.5249 20.8747C14.9928 20.5861 11.5575 20.6674 8.65985 20.9006C5.36801 21.1654 1.63786 16.5051 4.94033 16.5051H22.0042C22.0978 16.5168 22.1882 16.5335 22.2756 16.5549C25.4049 17.3211 21.7359 21.137 18.5249 20.8747Z"
										fill="#FBABA5"
									/>
									<path
										d="M10.5431 13.7807C10.8421 13.7623 11.1327 13.762 11.4258 13.7793C12.5896 13.8479 13.3447 15.0515 13.1108 16.284L12.9944 16.8967C12.8143 17.8457 12.104 18.6244 11.2108 18.6725C11.0677 18.6802 10.9307 18.6796 10.7881 18.671C9.87642 18.6158 9.14838 17.8279 8.966 16.8671L8.85556 16.2855C8.62174 15.0535 9.37736 13.8527 10.5431 13.7807Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M6.46426 14.6728C6.51133 14.6721 6.55828 14.6719 6.60515 14.6724C7.16051 14.6772 7.60796 14.964 7.89659 15.4023C8.18777 15.8445 8.31123 16.4352 8.20221 17.0137L8.19422 17.0561C8.02623 17.9475 7.36183 18.6813 6.55375 18.6778C5.72971 18.6741 5.05227 17.9209 4.88167 17.0155C4.7735 16.4416 4.89533 15.8521 5.1841 15.4089C5.47043 14.9696 5.9141 14.6812 6.46426 14.6728Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M20.0484 14.6728C20.0956 14.6721 20.1425 14.6719 20.1894 14.6724C20.7447 14.6772 21.1922 14.964 21.4808 15.4023C21.772 15.8445 21.8954 16.4352 21.7865 17.0137L21.7784 17.0561C21.6104 17.9475 20.9461 18.6813 20.138 18.6778C19.314 18.6741 18.6365 17.9209 18.4659 17.0155C18.3577 16.4416 18.4796 15.8521 18.7683 15.4089C19.0547 14.9696 19.4983 14.6812 20.0484 14.6728Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M15.2453 13.7807C15.5443 13.7623 15.835 13.762 16.128 13.7793C17.2918 13.8479 18.047 15.0515 17.813 16.284L17.6967 16.8967C17.5166 17.8457 16.8063 18.6244 15.9131 18.6725C15.77 18.6802 15.633 18.6796 15.4903 18.671C14.5787 18.6158 13.8506 17.8279 13.6683 16.8671L13.5579 16.2855C13.3241 15.0535 14.0796 13.8527 15.2453 13.7807Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
								</svg>
								<span className="Menu-List-Text">Front</span>
							</button>
							<button
								className={
									activeButton === 5
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(5)}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M18.1252 5.27748C8.66438 3.74741 6.515 6.1681 6.71838 7.76247V18.4607C6.71838 18.9144 6.75724 19.3783 6.95665 19.7858C8.44782 22.833 14.0493 22.4982 18.5651 21.5182C20.7681 21.0401 22.2088 19.0092 22.2088 16.7549V10.2455C22.2088 7.81187 20.5276 5.66601 18.1252 5.27748Z"
										fill="#FBABA5"
									/>
									<path
										d="M7.13779 13.5375C7.68473 13.5932 8.19825 13.5929 8.72833 13.54C9.87923 13.4249 10.613 12.2444 10.394 11.0331L10.1369 9.61183C9.96624 8.66799 9.26494 7.89341 8.36553 7.80547C8.07554 7.77711 7.82048 7.7787 7.53189 7.80864C6.61354 7.9039 5.89377 8.68646 5.72083 9.64275L5.46967 11.0315C5.25066 12.2425 5.98522 13.4203 7.13779 13.5375Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M17.154 13.5734C17.3346 13.5814 17.5125 13.5817 17.6906 13.5744C18.8236 13.5277 19.5637 12.3446 19.345 11.1266L19.2518 10.6072C19.0799 9.65004 18.3777 8.85328 17.5002 8.83856C17.4587 8.83786 17.4173 8.83804 17.3759 8.83899C16.4791 8.8599 15.7584 9.6685 15.5843 10.6383L15.4968 11.125C15.2783 12.3424 16.0188 13.5229 17.154 13.5734Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M12.3389 13.5625C12.663 13.585 12.9768 13.5853 13.294 13.564C14.4403 13.4874 15.1808 12.3008 14.959 11.0825L14.8067 10.2461C14.6337 9.29586 13.9305 8.51059 13.0408 8.46086C12.8994 8.45296 12.7641 8.45355 12.6233 8.46237C11.7143 8.51928 10.9928 9.31424 10.8175 10.2768L10.6711 11.0809C10.4495 12.2987 11.1906 13.4826 12.3389 13.5625Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M7.13797 13.5696C7.68491 13.5139 8.19844 13.5141 8.72847 13.5671C9.87937 13.6821 10.6132 14.8627 10.3941 16.074L10.1371 17.4953C9.96638 18.4391 9.26508 19.2136 8.36571 19.3016C8.07573 19.33 7.82065 19.3283 7.53207 19.2984C6.61371 19.2032 5.89395 18.4206 5.72101 17.4644L5.46985 16.0755C5.25085 14.8645 5.9854 13.6868 7.13797 13.5696Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M17.1542 13.5339C17.3349 13.5258 17.5127 13.5255 17.6909 13.5329C18.8238 13.5795 19.5639 14.7627 19.3452 15.9806L19.2519 16.5001C19.0801 17.4572 18.3778 18.2539 17.5004 18.2686C17.4589 18.2693 17.4176 18.2693 17.3761 18.2683C16.4793 18.2473 15.7587 17.4388 15.5845 16.469L15.4971 15.9822C15.2785 14.7649 16.0191 13.5843 17.1542 13.5339Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
									<path
										d="M12.3389 13.5447C12.663 13.5223 12.9768 13.522 13.294 13.5432C14.4403 13.6198 15.1808 14.8064 14.959 16.0247L14.8067 16.8611C14.6337 17.8114 13.9305 18.5967 13.0408 18.6463C12.8994 18.6543 12.7641 18.6537 12.6232 18.6449C11.7143 18.588 10.9928 17.7931 10.8175 16.8305L10.6711 16.0263C10.4495 14.8085 11.1906 13.6247 12.3389 13.5447Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.464516"
									/>
								</svg>
								<span className="Menu-List-Text">Left</span>
							</button>
							<button
								className={
									activeButton === 6
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(6)}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_402_1047)">
										<path
											d="M27 8.40089C25.9328 19.2436 17.8043 22.2388 13.8735 22.3811C9.80038 22.3434 1.32332 19.4946 0 8.40088H3.89526C4.99802 11.5868 8.53755 17.9267 13.8735 17.7986C16.6126 17.1896 22.219 14.4575 22.7312 8.40089H27Z"
											fill="#FBABA5"
										/>
										<path
											d="M15.3797 20.1916C16.3064 20.0097 17.1104 19.7415 17.9641 19.3402C18.972 18.8666 19.4642 17.5694 19.0808 16.5448L18.4937 14.9753C18.2089 14.2138 17.5029 13.7712 16.7141 13.9159C16.0575 14.0364 15.6245 14.185 14.9981 14.4897C14.2291 14.8636 13.7014 15.7186 13.6589 16.5985L13.5735 18.3642C13.5168 19.5343 14.331 20.3976 15.3797 20.1916Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M21.1014 16.6025C21.6163 16.2284 22.0625 15.8212 22.5048 15.3283C23.2349 14.5145 23.3237 13.0793 22.7557 12.213L22.0199 11.0907C21.5864 10.4296 20.8656 10.2342 20.233 10.6871C19.9117 10.9172 19.6723 11.1384 19.3903 11.4595C18.8215 12.1072 18.5993 13.1681 18.7985 14.0557L19.126 15.5151C19.3837 16.6637 20.2807 17.1987 21.1014 16.6025Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M24.0294 10.6362C24.167 10.3509 24.2871 10.0628 24.3957 9.75974C24.773 8.70651 24.2196 7.28458 23.2866 6.74868L23.1749 6.6845C22.4957 6.29441 21.7642 6.44913 21.4109 7.15037C21.3039 7.36267 21.2213 7.56391 21.1425 7.80104C20.8767 8.60101 21.1074 9.66107 21.6502 10.4005L21.7232 10.5C22.4606 11.5047 23.5611 11.6074 24.0294 10.6362Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M11.6731 20.1916C10.7463 20.0097 9.94238 19.7415 9.08856 19.3402C8.08069 18.8666 7.5886 17.5694 7.97192 16.5448L8.55898 14.9753C8.84387 14.2138 9.54988 13.7712 10.3387 13.9159C10.9953 14.0364 11.4283 14.185 12.0547 14.4897C12.8236 14.8636 13.3512 15.7186 13.3938 16.5985L13.4793 18.3642C13.5359 19.5343 12.7218 20.3976 11.6731 20.1916Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M5.95138 16.6025C5.43641 16.2284 4.99023 15.8212 4.54801 15.3283C3.81784 14.5145 3.72901 13.0793 4.29706 12.213L5.03284 11.0907C5.46631 10.4296 6.18708 10.2342 6.81972 10.6871C7.14107 10.9172 7.38043 11.1384 7.6624 11.4595C8.23119 12.1072 8.45344 13.1681 8.25426 14.0557L7.92679 15.5151C7.66904 16.6637 6.77201 17.1987 5.95138 16.6025Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
										<path
											d="M3.02339 10.6362C2.88585 10.3509 2.76567 10.0628 2.65708 9.75974C2.27977 8.70651 2.83312 7.28458 3.76616 6.74868L3.8779 6.6845C4.55706 6.29441 5.28856 6.44913 5.64188 7.15037C5.74884 7.36267 5.83144 7.56391 5.91025 7.80104C6.17612 8.60101 5.94537 9.66107 5.40263 10.4005L5.32958 10.5C4.59211 11.5047 3.49172 11.6074 3.02339 10.6362Z"
											fill="white"
											stroke="#C4C4C4"
											strokeWidth="0.411429"
										/>
									</g>
									<defs>
										<clipPath id="clip0_402_1047">
											<rect
												width="27"
												height="27"
												fill="white"
											/>
										</clipPath>
									</defs>
								</svg>
								<span className="Menu-List-Text">Bottom</span>
							</button>
							<button
								className={
									activeButton === 7
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => handleButtonClick(7)}
								ref={hideMaxillaryRef}
							>
								<svg
									width="27"
									height="27"
									viewBox="0 0 27 27"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.34163 13.5874C2.01761 12.4667 2.88234 12.103 3.44361 12.103H23.5894C24.647 12.2347 25.3626 12.9101 25.8357 13.8004C26.9787 15.9514 24.4937 17.8378 22.0771 17.5312C15.8592 16.7424 9.65574 17.0299 5.16202 17.5552C2.65998 17.8478 0.0405109 15.7444 1.34163 13.5874Z"
										fill="#FBABA5"
									/>
									<path
										d="M9.0514 8.88877C10.1452 8.71294 11.0691 8.71766 12.1102 8.88586C13.005 9.03043 13.5674 9.93081 13.3925 10.852L12.9266 13.3066C12.7928 14.0118 12.2547 14.5755 11.5617 14.6897C10.8158 14.8125 10.365 14.8056 9.62235 14.6832C8.91627 14.5668 8.36448 13.9987 8.22889 13.2843L7.76745 10.8531C7.59254 9.93182 8.15579 9.03274 9.0514 8.88877Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.35122"
									/>
									<path
										d="M4.37686 9.89265C5.04798 9.80632 5.65159 9.8077 6.2961 9.89027C7.18478 10.0041 7.74737 10.906 7.57351 11.8287L7.28389 13.3654C7.1504 14.0739 6.61416 14.6457 5.92553 14.7383C5.50474 14.7948 5.18874 14.7918 4.77003 14.734C4.06782 14.637 3.51792 14.0605 3.38269 13.3429L3.09756 11.8298C2.92369 10.9072 3.48715 10.0071 4.37686 9.89265Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.35122"
									/>
									<path
										d="M20.4158 9.89265C21.087 9.80632 21.6906 9.8077 22.3351 9.89027C23.2238 10.0041 23.7864 10.906 23.6124 11.8287L23.3229 13.3654C23.1893 14.0739 22.6531 14.6457 21.9645 14.7383C21.5437 14.7948 21.2277 14.7918 20.809 14.734C20.1068 14.637 19.5569 14.0605 19.4216 13.3429L19.1365 11.8298C18.9626 10.9072 19.5261 10.0071 20.4158 9.89265Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.35122"
									/>
									<path
										d="M14.5995 8.88877C15.6934 8.71294 16.6172 8.71766 17.6583 8.88586C18.5532 9.03043 19.1156 9.93081 18.9407 10.852L18.4748 13.3066C18.341 14.0118 17.8029 14.5755 17.1099 14.6897C16.364 14.8125 15.9131 14.8056 15.1706 14.6832C14.4644 14.5668 13.9126 13.9987 13.777 13.2843L13.3156 10.8531C13.1407 9.93182 13.7039 9.03274 14.5995 8.88877Z"
										fill="white"
										stroke="#C4C4C4"
										strokeWidth="0.35122"
									/>
								</svg>
								<span className="Menu-List-Text">Lower</span>
							</button>
							<div className="bounty_divider"></div>
							<button
								className={
									activeButton === 8
										? "Menu-List-1   active"
										: "Menu-List-1"
								}
								ref={toggleAttachmentRef}
								onClick={() => toggleAttachment()}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M11.9891 23.3091C10.5791 23.3091 9.1691 23.1091 7.1591 22.7091C5.3291 22.3391 3.8991 20.9091 3.4991 19.0591L1.3491 6.73907C0.959096 4.28907 2.3891 2.05907 4.6591 1.54907C10.1791 0.409067 14.1391 0.409067 19.3091 1.55907C21.6291 2.07907 23.1191 4.40907 22.6191 6.76907L20.3691 19.0391C19.9791 20.8691 18.5591 22.3391 16.8291 22.7091C14.8191 23.1091 13.3991 23.3091 11.9891 23.3091ZM2.3391 6.57907L4.4791 18.8591C4.7891 20.3091 5.9191 21.4391 7.3491 21.7191C11.2491 22.4991 12.7191 22.4991 16.6191 21.7191C17.9591 21.4291 19.0791 20.2691 19.3791 18.8391L21.6291 6.56907C22.0191 4.72907 20.8691 2.92907 19.0791 2.52907C14.0691 1.41907 10.2191 1.41907 4.8691 2.52907C3.1191 2.91907 2.0291 4.65907 2.3291 6.57907H2.3391Z"
										fill="#2D5D9F"
									/>
									<path
										d="M13.2309 15.9516H10.7509C10.0309 15.9516 9.46094 15.3816 9.46094 14.6616V9.14156C9.46094 8.53156 10.0109 7.85156 10.7509 7.85156H13.2309C13.9509 7.85156 14.5209 8.42156 14.5209 9.14156V14.6616C14.5209 15.3816 13.9509 15.9516 13.2309 15.9516ZM10.7509 8.85156C10.5909 8.85156 10.4609 9.06156 10.4609 9.14156V14.6616C10.4609 14.8316 10.5709 14.9516 10.7509 14.9516H13.2309C13.4109 14.9516 13.5209 14.8416 13.5209 14.6616V9.14156C13.5209 8.96156 13.4109 8.85156 13.2309 8.85156H10.7509Z"
										fill="#2D5D9F"
									/>
								</svg>
								<span className="Menu-List-Text">Attach</span>
							</button>
							<button
								className={
									activeButton === 9
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								ref={iprRef}
								onClick={() => toggleIprViewer()}
							>
								<svg
									width="22"
									height="24"
									viewBox="0 0 22 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M10.9896 23.3222C9.57955 23.3222 8.15955 23.1222 6.14955 22.7222C4.31955 22.3522 2.87955 20.9222 2.48955 19.0622L0.329551 6.73219C-0.0604491 4.28219 1.36955 2.04219 3.64955 1.54219C9.16955 0.392187 13.1395 0.392187 18.3195 1.54219C20.6495 2.06219 22.1295 4.40219 21.6395 6.76219L19.3895 19.0522C18.9995 20.8822 17.5696 22.3522 15.8396 22.7222C13.8196 23.1222 12.4096 23.3222 10.9896 23.3222ZM1.31955 6.57219L3.45955 18.8722C3.76955 20.3222 4.89955 21.4522 6.33955 21.7422C10.2396 22.5222 11.7195 22.5222 15.6295 21.7422C16.9796 21.4522 18.0895 20.2922 18.3995 18.8522L20.6495 6.56219C21.0395 4.72219 19.8896 2.91219 18.0996 2.51219C13.0796 1.39219 9.21955 1.39219 3.85955 2.51219C2.10955 2.90219 1.00955 4.64219 1.31955 6.57219Z"
										fill="#2D5D9F"
									/>
								</svg>
								<span className="Menu-List-Text">IPR</span>
							</button>
							<button
								className={
									activeButton === 10
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => toggleSuperImpose()}
								ref={superImposeRef}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g clipPath="url(#clip0_1213_109)">
										<path
											d="M23.0995 9C23.0995 7.1 21.5995 5.6 19.6995 5.5C18.6995 5.5 17.8995 5.5 16.9995 5.6V5.4C17.3995 3.6 16.1995 1.7 14.3995 1.3C10.4995 0.4 7.4995 0.4 3.2995 1.3C1.4995 1.7 0.399502 3.5 0.699502 5.4L2.2995 14.7C2.5995 16.2 3.6995 17.3 5.1995 17.6C6.5995 17.9 7.6995 18.1 8.7995 18.1C8.9995 18.1 9.1995 18.1 9.3995 18.1L10.5995 21.2C11.1995 22.5 12.4995 23.4 13.9995 23.4C17.0995 23.4 18.2995 23.1 21.1995 21.9C22.4995 21.3 23.2995 20 23.2995 18.5L23.0995 9ZM5.3995 16.6C4.3995 16.4 3.4995 15.6 3.2995 14.5L1.6995 5.2C1.4995 3.9 2.1995 2.6 3.4995 2.3C7.4995 1.5 10.3995 1.5 14.1995 2.3C15.4995 2.6 16.2995 3.9 15.9995 5.2V5.7C13.5995 6 11.4995 6.7 8.7995 7.8C7.0995 8.6 6.3995 10.5 7.0995 12.3L8.9995 17.1C7.9995 17.1 6.9995 16.9 5.3995 16.6ZM20.7995 20.9C17.9995 22.1 16.9995 22.3 13.9995 22.3C12.8995 22.3 11.9995 21.7 11.4995 20.7L7.9995 12C7.4995 10.7 7.9995 9.3 9.1995 8.8C12.9995 7.1 15.7995 6.5 19.6995 6.6C20.9995 6.6 22.0995 7.7 22.0995 9.1L22.3995 18.5C22.3995 19.5 21.6995 20.5 20.7995 20.9Z"
											fill="#2D5D9F"
										/>
									</g>
									<defs>
										<clipPath id="clip0_1213_109">
											<rect
												width="24"
												height="24"
												fill="white"
											/>
										</clipPath>
									</defs>
								</svg>
								<span className="Menu-List-Text">Super</span>
							</button>
							<button
								className={
									activeButton === 11
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => toggleGridView()}
								ref={gridRef}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M16.9998 23.3197H6.99984C3.73984 23.3197 1.08984 20.6697 1.08984 17.4097V6.58969C1.08984 3.32969 3.73984 0.679688 6.99984 0.679688H16.9998C20.2598 0.679688 22.9098 3.32969 22.9098 6.58969V17.4097C22.9098 20.7197 20.3198 23.3197 16.9998 23.3197ZM6.99984 1.67969C4.28984 1.67969 2.08984 3.87969 2.08984 6.58969V17.4097C2.08984 20.1197 4.28984 22.3197 6.99984 22.3197H16.9998C19.7498 22.3197 21.9098 20.1597 21.9098 17.4097V6.58969C21.9098 3.87969 19.7098 1.67969 16.9998 1.67969H6.99984Z"
										fill="#2D5D9F"
									/>
									<path
										d="M12.3504 1.17969H11.6504V22.4097H12.3504V1.17969Z"
										fill="#2D5D9F"
									/>
									<path
										d="M5.45 1.38281H4.75V22.3128H5.45V1.38281Z"
										fill="#2D5D9F"
									/>
									<path
										d="M18.7 1.45312H18V22.6831H18.7V1.45312Z"
										fill="#2D5D9F"
									/>
									<path
										d="M22.1398 11.3828H1.58984V12.0828H22.1398V11.3828Z"
										fill="#2D5D9F"
									/>
									<path
										d="M22.5806 17.8828H1.39062V18.5828H22.5806V17.8828Z"
										fill="#2D5D9F"
									/>
									<path
										d="M22.4095 5.15625H1.26953V5.85625H22.4095V5.15625Z"
										fill="#2D5D9F"
									/>
								</svg>
								<span className="Menu-List-Text">Grid</span>
							</button>
							<button
								className={
									activeButton === 12
										? "Menu-List-1  active"
										: "Menu-List-1"
								}
								onClick={() => toggleSplitView()}
								ref={toggleSplitViewRef}
							>
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13.8303 2.6875H12.5703V3.6875H13.8303V2.6875Z"
										fill="#2D5D9F"
									/>
									<path
										d="M15.7092 21.1797H14.4492V20.1797H15.7092C15.8592 20.1797 15.9992 20.1797 16.1392 20.1597L16.2292 21.1597C16.0592 21.1797 15.8792 21.1797 15.7092 21.1797ZM17.2792 20.9497L16.9892 19.9897C17.5492 19.8197 18.0692 19.5497 18.5292 19.1797L19.1592 19.9597C18.5992 20.4097 17.9592 20.7497 17.2792 20.9497ZM19.9292 19.2097L19.1692 18.5597C19.5492 18.1097 19.8392 17.5997 20.0192 17.0397L20.9692 17.3497C20.7492 18.0297 20.3992 18.6597 19.9292 19.2097ZM21.1992 16.3097L20.2092 16.1897C20.2292 16.0097 20.2392 15.8297 20.2392 15.6397V14.2997H21.2392V15.6397C21.2392 15.8597 21.2292 16.0897 21.1992 16.2997V16.3097ZM21.2392 13.3297H20.2392V11.3797H21.2392V13.3297ZM21.2392 10.4097H20.2392V8.45969H21.2392V10.4097ZM20.1892 7.55969C20.0992 6.97969 19.9092 6.42969 19.5992 5.91969L20.4592 5.40969C20.8292 6.02969 21.0692 6.70969 21.1692 7.41969L20.1792 7.56969L20.1892 7.55969ZM19.0892 5.20969C18.6992 4.76969 18.2292 4.40969 17.6992 4.14969L18.1392 3.24969C18.7792 3.56969 19.3492 3.99969 19.8292 4.53969L19.0792 5.20969H19.0892ZM16.8792 3.83969C16.4992 3.73969 16.0992 3.67969 15.7092 3.67969H15.0792V2.67969H15.7092C16.1992 2.67969 16.6792 2.73969 17.1392 2.86969L16.8792 3.83969Z"
										fill="#2D5D9F"
									/>
									<path
										d="M13.8303 20.1797H12.5703V21.1797H13.8303V20.1797Z"
										fill="#2D5D9F"
									/>
									<path
										d="M12.5698 21.1831H8.28977C5.23977 21.1831 2.75977 18.7031 2.75977 15.6531V8.23312C2.75977 5.18312 5.23977 2.70312 8.28977 2.70312H12.5698V3.70312H8.28977C5.78977 3.70312 3.75977 5.73312 3.75977 8.23312V15.6531C3.75977 18.1531 5.78977 20.1831 8.28977 20.1831H12.5698V21.1831Z"
										fill="#2D5D9F"
									/>
									<path
										d="M13.8594 0.671875H12.8594V23.3219H13.8594V0.671875Z"
										fill="#2D5D9F"
									/>
								</svg>
								<span className="Menu-List-Text">Split</span>
							</button>
						</div>

						{/* <div style={{ display: "flex", gap: "10px" }}>
							<button
								style={{
									display: globalState.isFileDropped
										? "block"
										: "none",
								}}
								ref={globalState.addIprButtonHeaderRef}
								onClick={() =>
									globalState.actions.activateAddIpr(
										globalState
									)
								}
							>
								Add IPR
							</button>
							<button
								style={{
									display: globalState.isFileDropped
										? "block"
										: "none",
								}}
								onClick={() =>
									globalState.actions.generateJsonData(
										globalState
									)
								}
							>
								Generate Json Data
							</button>
							<span
								className="spanIprForText"
								style={{ display: "none" }}
								ref={globalState.spanRefForToothSelectionInIpr}
							>
								Please select the tooth to add Ipr
							</span>
						</div> */}
					</div>
				</div>
			</div>
		</>
	);
}
