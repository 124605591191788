import * as THREE from 'three'

class ObjectGrp3D extends THREE.Object3D {
    constructor(threeViewer) {
        super();
        this.threeViewer = threeViewer;
        this.mandibularGroup = new THREE.Group();
        this.mandibularGroup.name = "mandibular";
        this.maxillaryGroup = new THREE.Group();
        this.maxillaryGroup.name = "maxillary";

        this.add(this.mandibularGroup)
        this.add(this.maxillaryGroup)    
        this.threeViewer.scene.add(this)   
        this.name = "allToothModel" 

    }
    toggleAttachment(inBool) {
        let attachments = this.getObjectsByProperty("customType", "attachment")
        attachments.forEach((attachment) => {
            attachment.visible = inBool
        })
    }
}

export { ObjectGrp3D }