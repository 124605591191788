import React, { useState, useEffect, useContext } from "react";
import "../Header/HeaderNav.css";
import HeaderLogo from "./HeaderLogo";
import HeaderNavMenu from "./HeaderNavMenu";
import HeaderNavRight from "./HeaderNavRight";
import stateContext from "../../context/stateGlobal/StateContext";

export default function HeaderNav({ appDivRef, shareLinkToken, noMenu, type }) {
	// Create state to toggle button menu
	let publicUrl = process.env.PUBLIC_URL;
	const [toggleBtnMenu, setToggleBtnMenu] = useState(false);
	const globalState = useContext(stateContext);

	const setWindowDimensions = () => {
		setToggleBtnMenu(window.innerWidth > 1280);
	};
	useEffect(() => {
		// Update button menu according to window size
		setToggleBtnMenu(window.innerWidth > 1280);

		window.addEventListener("resize", setWindowDimensions);
		return () => {
			window.removeEventListener("resize", setWindowDimensions);
		};
	}, []);

	useEffect(() => {
		if (shareLinkToken) {
			appDivRef.current.classList.add("sharedLink");
		}
	}, [shareLinkToken]);
	

	return (
		<div
			className={`HeaderNav-section 
			${globalState.addingIprDataBool === true ? "addIprHeader" : ""}
			${!noMenu ? " withEditor" : ""} 
			${shareLinkToken ? "shareLink" : ""}`}
		>
			<HeaderLogo />
			{shareLinkToken ? (
				<button
					className="toggle-btn"
					onClick={() => {
						setToggleBtnMenu(true);
						appDivRef.current.classList.add("overlay");
					}}
				>
					<img
						src={`${publicUrl}/assets/img/bar.svg`}
						alt="bar"
					></img>
				</button>
			) : (
				<HeaderNavRight
					setToggleBtnMenu={setToggleBtnMenu}
					appDivRef={appDivRef}
				/>
			)}

			{!noMenu && (
				<HeaderNavMenu
					toggleBtnMenu={toggleBtnMenu}
					setToggleBtnMenu={setToggleBtnMenu}
					appDivRef={appDivRef}
					type={type}
					style={{
						display: globalState.isLoggedIn ? "none" : "flex",
					}}
				/>
			)}
		</div>
	);
}
