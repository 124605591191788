import { useState, useEffect, useRef, useContext } from 'react';
import { setupViewer } from './setupViewer';
import useActions3d from './useActions3d';
import StateContext from '../../context/stateGlobal/StateContext';
import * as THREE from 'three';
import { ObjectGrp3D } from './utils/ObjectGrp3D';
import jsonData from "../../input.json"
import LoadingManger3D from './LoadingManger3D';


const use3DViewerRight = ({ container }) => {
    const globalState = useContext(StateContext);
    const [viewerRight, setViewer] = useState({});
    const animationFrameId = useRef(null); // for the rendering loop
    const actionsRight = useActions3d(viewerRight)
    window.actions = actionsRight
    
    async function initializeViewer2() {
        if (container.current && !globalState.initialized) {
            // Viewer Setup
            const { threeViewer } = setupViewer(container, animationFrameId);
            window.threeViewer2 = threeViewer
            globalState.threeViewerRight = threeViewer
        }
    }

    useEffect(() => {
        initializeViewer2();
        // Cleanup function to stop the animation loop when the component unmounts
        return () => {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
        };

    }, [container]);

    useEffect(() => {
        if (viewerRight) {
            globalState.setActions(actionsRight);  
        }
    }, [viewerRight]); // React to changes in viewer

    return { viewer: viewerRight, actions: actionsRight };
};

export default use3DViewerRight;

const addModelsInThreeViewerRight = (threeViewer,noOfModels) => {
    // adding a tooth model to threeViewerRight so that user can use split view
    let maxillaryObject = threeViewer.scene.getObjectByName(`maxillary_${noOfModels}`)
    let mandibularObject = threeViewer.scene.getObjectByName(`mandibular_${noOfModels}`)
    let cloneMandibularObject, cloneMaxillaryObject

    // Check if the objects exist in the scene before trying to set visibility
    if (maxillaryObject) {
        cloneMaxillaryObject = maxillaryObject.clone()
    }
    else if (!maxillaryObject) {
        for (let j = noOfModels; j > 0; j--) {
            if (threeViewer.scene.getObjectByName(`maxillary_${j}`)) {
                cloneMaxillaryObject = threeViewer.scene.getObjectByName(`maxillary_${j}`).clone()
                break
            }
        }
    }

    if (mandibularObject) {
        cloneMandibularObject = mandibularObject.clone()
    }
    else if (!mandibularObject) {
        for (let k = noOfModels; k > 0; k--) {
            if (threeViewer.scene.getObjectByName(`mandibular_${k}`)) {
                cloneMandibularObject = threeViewer.scene.getObjectByName(`mandibular_${k}`).clone()
                break
            }
        }
    }
    cloneMandibularObject.name = "mandibularRight"
    cloneMaxillaryObject.name = "maxillaryRight"
    cloneMandibularObject.visible = true
    cloneMaxillaryObject.visible = true
    let toothModelRight = new THREE.Object3D()
    toothModelRight.add(cloneMandibularObject)
    toothModelRight.add(cloneMaxillaryObject)
    threeViewer.threeViewerRight.scene.add(toothModelRight)

    // Compute the bounding box of the object group
    let bBox = new THREE.Box3().setFromObject(toothModelRight);
    let center = new THREE.Vector3();
    bBox.getCenter(center);

    // Translate the model to the center of the bounding box
    toothModelRight.position.sub(center);
}

export { addModelsInThreeViewerRight }