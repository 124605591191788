import { ThreeViewer } from "./utils/ThreeViewer";

export const setupViewer = (container, animationFrameId) => {
    const element = container?.current;
    
    // Three viewer initialization
    const threeViewer = new ThreeViewer({
        element: element,
        animationFrameId: animationFrameId
    })
    threeViewer.initViewer()

    let scene = threeViewer.scene
    let camera = threeViewer.camera
    let renderer = threeViewer.renderer
    let controls = threeViewer.controls
    
    
    return { scene, camera, renderer, controls, threeViewer };
};
