import React, { useContext, useEffect } from "react";
import HeaderNav from "./Components/Header/HeaderNav";
import Content from "./Components/Content/Content";
import Footer from "./Components/Footer/Footer";
import GlobalState from "./context/stateGlobal/GlobalState";
import LoadingBar from "./Components/Loader/loadingBar";
import stateContext from "./context/stateGlobal/StateContext";
import "../src/index.css";
import "../src/css/responsive.css";

function AddIprData() {
	const appDivRef = React.useRef(null);
	const globalState = useContext(stateContext);

	return (
		<div
			className={`App addIprData ${
				globalState.isLoggedIn ? "loggedIn" : "notLoggedIn"
			}`}
			// addIprInput
			// drop-zone
			ref={appDivRef}
		>
			<HeaderNav appDivRef={appDivRef} />
			<Content isAddIpr={true} />
			<Footer />
			<LoadingBar />
		</div>
	);
}

export default AddIprData;
