let mainObject = [];
export function createAccumulator() {
    function addObject(obj) {
        mainObject.push(obj);
    }

    function getMainObject() {
        return mainObject;
    }

    function setMainObject(obj) {
        mainObject = obj;
    }

    return { addObject, getMainObject, setMainObject };
}

export function downloadJSONData(inData) {
    let data = inData.map((element) => {
        // Create a new object with all properties except 'id'
        const { id, ...rest } = element;
        return rest;
    });

    // Sort the array based on element.step_no in ascending order
    data.sort((a, b) => a.step_no - b.step_no);

    let jsonString = JSON.stringify(data, null, 2);
    let blob = new Blob([jsonString], { type: "application/json" });

    let url = URL.createObjectURL(blob);

    // Extracting the patientUploadName from URL parameters
    const params = new URLSearchParams(window.location.search);
    const patientUploadName = params.get("patientUploadName") || "DefaultName"; // Fallback in case it's not present

    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${patientUploadName} IPR Data.json`; // Use the parameter in the filename
    downloadLink.textContent = "Download File";

    downloadLink.click();
    URL.revokeObjectURL(url);
}

export function generateUniqueId() {
    // Generate a timestamp
    const timestamp = new Date().getTime();

    // Generate a random number
    const random = Math.floor(Math.random() * 1000000);

    // Concatenate timestamp and random number to create a unique ID
    const uniqueId = `${timestamp}${random}`;

    return uniqueId;
}
