import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as THREE from 'three'
import { ObjectGrp3D } from './utils/ObjectGrp3D';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

class IprLoadingManager {
    constructor(threeViewer) {
        // Initialize variables
        this.modelUrls = [];
        this.threeViewer = threeViewer;
        this.allModels = 0
        this.patientId = null
        this.caseId = null
        this.objLoader = null


        this.initGltfLoader()
    }

    initGltfLoader() {
        // const loader = new GLTFLoader();
        // const dracoUrl = 'https://storage.googleapis.com/ds-stl-viewer/draco/'
        // const dracoLoader = new DRACOLoader();
        // dracoLoader.setDecoderPath(dracoUrl);
        // loader.setDRACOLoader(dracoLoader);
        const loader = new OBJLoader();
        this.objLoader = loader
    }

    async loadAllModels(allFiles, inViewer,globalState) {
        if (!allFiles || allFiles.length === 0) {
            console.warn("No models found in input data");
            return;
        }

        let objectGrp3D = new ObjectGrp3D(inViewer);
        let promises = [];

        for (let i = 1; i <= allFiles.length; i++) {
            const element = allFiles[i - 1];

            // Load the mandibular model
            if (element.Mandibular) {
                const mandibularPromise = this.loadObjModel(element.Mandibular).then((object) => {
                    object.name = `mandibular_${i}`;
                    if (i !== 1) object.visible = false;
                    objectGrp3D.mandibularGroup.add(object);
                });

                promises.push(mandibularPromise);
            }

            // Load the maxillary model
            if (element.Maxillary) {
                const maxillaryPromise = this.loadObjModel(element.Maxillary).then((object) => {
                    object.name = `maxillary_${i}`;
                    if (i !== 1) object.visible = false;
                    objectGrp3D.maxillaryGroup.add(object);
                });

                promises.push(maxillaryPromise);
            }
        }

        try {
            await Promise.all(promises);
            globalState.loadingDivRef.current.style.display = 'none'
            globalState.setInitialized(true)

            let bBox = new THREE.Box3().setFromObject(objectGrp3D);
            let center = new THREE.Vector3();
            bBox.getCenter(center);

            objectGrp3D.position.sub(center);

            inViewer.objectGrp3D = objectGrp3D;

            return objectGrp3D;
        } catch (error) {
            console.error("Error loading models:", error);
            return null;
        }
    }

    loadObjModel(file) {
        let loader = this.objLoader
    
        return new Promise((resolve, reject) => {
            const url = URL.createObjectURL(file);
    
            loader.load(url, function (object) {
                object.traverse((e) => {
                    if (!e.isMesh) {
                        return;
                    }
                    e.geometry.computeVertexNormals();
    
                    let material = new THREE.MeshPhongMaterial({
                        transparent: false,
                        reflectivity: 0.5,
                        side: THREE.FrontSide,
                        specular: "rgb(79, 13, 13)",
                        shininess: 1500,
                    });
    
                    if (e.name === "Mandibular" || e.name === "Maxillary") {
                        material.color.set(0xfc8697);
                    } else if (e.name.startsWith('Tooth_')) {
                        material.color.set(0xffffff);
                    } else {
                        // Add customType to identify attachments
                        e["customType"] = "attachment";
                        material.color.set(0xff680a);
                    }
                    e.material = material;
                });
    
                resolve(object);
    
                // Revoke the object URL to free up resources
                URL.revokeObjectURL(url);
            }, undefined, reject);
        });
    }
}

export default IprLoadingManager;

