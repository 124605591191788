import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import GlobalState from "./context/stateGlobal/GlobalState";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AddIprData from "./AddIprData";
import Home from "./Components/Home/Home";
import Login from "./Components/Header/Login";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
		<GlobalState>
			<Routes>
				<Route path="/:patientId/:caseId/:uuid" element={<App />} />
				<Route path="/share/:token" element={<App />} />
				<Route path="/add-ipr-Data" element={<AddIprData />} />
				<Route path="/login" element={<Login />} />
				<Route path="/" element={<Home />} />
			</Routes>
		</GlobalState>
	</BrowserRouter>
);
