import React, { useEffect, useRef, useContext } from 'react';
import "./loadingBar.css"
import StateContext from '../../context/stateGlobal/StateContext';

export default function LoadingBar() {
  // Create a ref for the loadingDiv element
  let publicUrl = process.env.PUBLIC_URL
  const globalState = useContext(StateContext);
  const initialized = globalState.initialized
  const loadingDivRef = globalState.loadingDivRef
  
  useEffect(()=>{
    if(initialized) {
        loadingDivRef.current.style.display = "none"
    }
  },[initialized])

  return (
    <>
      <div className='loadingDiv' id='loadingDiv' ref={loadingDivRef}>
        <img src={`${publicUrl}/assets/img/loading-spinner.svg`} alt="" />
      </div>
    </>
  );
}

