import AWS from 'aws-sdk';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as THREE from 'three'
import { ObjectGrp3D } from './utils/ObjectGrp3D';
import axios from 'axios';

class LoadingManger3D {
  constructor(threeViewer) {
    // Initialize variables
    this.modelUrls = [];
    this.threeViewer = threeViewer;
    this.allModels = 0
    this.patientId = null
    this.caseId = null

    this.createS3Instance()
    this.initGltfLoader()
  }
  createS3Instance() {
    // Create S3 instance with AWS credentials and region
    this.s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION,
      endpoint: process.env.REACT_APP_ENDPOINT,
    });

    // Update AWS configuration with credentials, region, and endpoint
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
      region: process.env.REACT_APP_REGION,
      endpoint: process.env.REACT_APP_ENDPOINT,
    });
  }
  initGltfLoader() {
    const loader = new GLTFLoader();
    const dracoUrl = '/assets/draco/';
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath(dracoUrl);
    loader.setDRACOLoader(dracoLoader);
    this.loader = loader
  }

  async readJSONFile(fileName) {
    try {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: fileName,
      };

      // Read the file from S3
      const data = await this.s3.getObject(params).promise();
      const fileContent = data.Body.toString('utf-8');
    

      // Parse the JSON data
      const jsonData = JSON.parse(fileContent);
      this.jsonData = jsonData;
      return jsonData
    } catch (err) {
      console.error(`Error reading file '${fileName}':`, err);
      throw err;
    }
  }


  async readTextFileName(fileName) {
    try {
      // Set the parameters for listing objects in the bucket
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
      };

      // Retrieve the list of objects in the bucket
      const data = await this.s3.listObjects(params).promise();

      // Iterate over each object in the bucket
      for (const object of data.Contents) {
        // Fetch the content of the object
        const response = await fetch(object.Key);
        const fileContent = await response.text();

      }
    } catch (err) {
      // Handle any errors that occur during the process
      console.error('Error:', err);
      throw err;
    }
  }

  async generatePublicUrl(fileName, expirationTimeInSeconds) {
    return new Promise((resolve, reject) => {
      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: fileName,
        Expires: expirationTimeInSeconds,
      };

      // Generate a signed URL for the getObject operation with the given params
      this.s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      });
    });
  }

  async loadAllModels(models, inViewer) {
    // Check if models array is empty
    if (!models || models.length === 0) {
      console.warn("No models found in input data");
      return;
    }


    // Create an instance of ObjectGrp3D
    let objectGrp3D = new ObjectGrp3D(inViewer);

    let promises = [];

    // Loop through each model in the array
    for (let i = 1; i <= models.length; i++) {
      const element = models[i-1];

      // Load the mandibular model
      let mandibular
      if (element.Mandibular) {
        mandibular = this.loadModel(element.Mandibular).then((e) => {
          e.name = `mandibular_${i}`;
          if (i !== 1) e.visible = false;
          objectGrp3D.mandibularGroup.add(e);
        });
      }
      
      // Load the maxillary model
      let maxillary
      if (element.Maxillary) {
        maxillary = this.loadModel(element.Maxillary).then((e) => {
          e.name = `maxillary_${i}`;
          if (i !== 1) e.visible = false;
          objectGrp3D.maxillaryGroup.add(e);
        });
      }

      // Add the promises to the array
      promises.push(mandibular);
      promises.push(maxillary);
    }

    try {
      // Wait for all the promises to resolve
      await Promise.all(promises);

      // Compute the bounding box of the object group
      let bBox = new THREE.Box3().setFromObject(objectGrp3D);
      let center = new THREE.Vector3();
      bBox.getCenter(center);
      
      // Translate the model to the center of the bounding box
      objectGrp3D.position.sub(center);

      // Set objectGrp3D to the threeViewer object
      inViewer.objectGrp3D = objectGrp3D;

      return objectGrp3D;
    } catch (error) {
      console.error("Error loading models:", error);
      return null;
    }
  }
  async processJsonData(jsonData, expirationTimeInSeconds) {
    // Iterate over each model in the JSON data
    for (let i = 0; i < jsonData.models.length; i++) {
      const model = jsonData.models[i];

      // Generate public URLs for the Mandibular and Maxillary models
      let publicUrlForMandibular = null
      let publicUrlForMaxillary = null
      if (model.Mandibular) {
         publicUrlForMandibular = await this.generatePublicUrl(model.Mandibular, expirationTimeInSeconds);
      }
      if (model.Maxillary) {
         publicUrlForMaxillary = await this.generatePublicUrl(model.Maxillary, expirationTimeInSeconds);
      }

      // Create a temporary object with the generated URLs
      let tempObj = {
        Mandibular: publicUrlForMandibular,
        Maxillary: publicUrlForMaxillary
      }

      // Add the temporary object to the modelUrls array
      this.modelUrls.push(tempObj);

      // Log the current state of the modelUrls array
    }

    // Load all the models using the generated URLs and the threeViewer
    return await this.loadAllModels(this.modelUrls, this.threeViewer);
  }
  loadModel(modelUrl) {
    let loader = this.loader

    // Assuming you want to load all models in parallel
    return new Promise((resolve, reject) => {
      loader.load(modelUrl, function (gltf) {
        let model = gltf.scene
        gltf.scene.traverse((e) => {
          if (!e.isMesh) {
            return;
          }
          e.geometry.computeVertexNormals();
  
          let material = new THREE.MeshPhongMaterial({
            transparent: false,
            reflectivity: 0.5,
            side: THREE.FrontSide,
            specular: ("rgb(79, 13, 13)"),
            shininess: 1500,
          })

          if (e.name === "Mandibular" || e.name === "Maxillary") {
            material.color.set(0xfc8697);
          } else if (e.name.startsWith('Tooth_')) {
            material.color.set(0xffffff);
          } else {
            // Add customType to identify attachments
            e["customType"] = "attachment"
            material.color.set(0xff680a);
          }
          e.material = material
        });


        resolve(gltf.scene);
      });
    })
  }
  async extractPatientCaseData(patientId, caseId, uuid) {
      // var path = window.location.pathname;

      // // Define a regular expression pattern to match path parameters
      // var regex = /\/([^/]+)/g;

      // // Initialize an array to store the extracted path parameters
      // var pathParams = [];

      // // Use a loop to match the pattern and extract path parameters
      // var match;
      // while ((match = regex.exec(path)) !== null) {
      //   pathParams.push(match[1]);
      // }

      // // Check if the path parameters are minimum 3 or not
      // if (pathParams.length < 3) {
      //   console.error("Invalid URL");
      //   return;
      // }

      // // if params is greater than 3, then use the last 3 params
      // if (pathParams.length > 3) {
      //   pathParams = pathParams.slice(-3);
      // }

      this.patientId = patientId
      this.caseId = caseId
      this.uuid = uuid
    // Construct the URL using the parameters
    const jsonDataURL = "data/" + patientId + '/' + caseId + "/" + uuid + ".json";

    // Call the readTextFile function with the generated URL
    let jsonData = await this.readJSONFile(jsonDataURL);
    return jsonData;  
  }

  loadTreatmentPlan() {
    return new Promise((resolve, reject) => {
      var params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Delimiter: '/',
        Prefix: "data/" + this.patientId + '/'
      };

      this.s3.listObjectsV2(params, function (err, data) {
        if (err) {
          resolve(null)
          console.log(err, err.stack)
        } else {
          console.log(data.CommonPrefixes)
          resolve(data.CommonPrefixes)
        };
      });
    })
  }

  async getTreatmentPlan(patientId) {
    //patientId is the id by which the url is generated through the api
    return new Promise(async (resolve, reject) => {
      try {
        const res = await axios.get(`${`https://pacs-auth.dent-scan.com/api/ds-viewer-links?filters[$and][0][patient_id][$eqi]=${patientId}`}`, {
          headers: {
            'Authorization': `Bearer ${'a9ac04cc8803180797b88b635a465b74a09862dc7014bfb98f59c18d87ed8e81e7c018d769ee645020b92e1f928bd519d8560304ddc5b4af0caf81f804cb2eea45e34059578589c447a5abf7d34486ce9bfc06d3fb42dc8075d12d9e9b9e0a7f26c9b8814477ee29f6b12762e2ae8de76a2ea522ddbb9b878ac5720cce0baef2'}`,
          },
        });
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  }
   
}

export default LoadingManger3D;

