import React, { useState, useEffect, useContext } from "react";
import AWS from "aws-sdk";
import StateContext from "../../context/stateGlobal/StateContext";

const DownloadReport = () => {
	const globalState = useContext(StateContext);
	// const [downloadUrl, setDownloadUrl] = useState("");
	const [analysisReportLink, setAnalysisReportLink] = useState("");

	useEffect(() => {
		if (globalState.tempTreatmentData) {
			// let currentTreatmentPlan = globalState.selectedTreatmentPlan - 1;
			let currentTreatmentPlan = globalState.tempTreatmentData.find(plan => parseInt(plan.attributes.treatment_plan_version) === globalState.selectedTreatmentPlan);
			// console.log(currentTreatmentPlan);
			setAnalysisReportLink(
				currentTreatmentPlan.attributes
					.analysis_report_link
			);
		}
	}, [globalState.tempTreatmentData, globalState.selectedTreatmentPlan]);

	const handleDownloadReport = async () => {
		if (!analysisReportLink || analysisReportLink === "") {
			alert("There is no report uploaded for this patient");
		} else {
			const s3 = new AWS.S3({
				accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
				secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
				region: process.env.REACT_APP_REGION,
				endpoint: process.env.REACT_APP_ENDPOINT,
			});

			const bucketName = process.env.REACT_APP_BUCKET_NAME;
			const fileKey = analysisReportLink;

			try {
				const { Body } = await s3
					.getObject({ Bucket: bucketName, Key: fileKey })
					.promise();
				const blob = new Blob([Body], { type: "application/pdf" });
				const url = URL.createObjectURL(blob);

				const a = document.createElement("a");
				a.href = url;
				a.download = "report.pdf";

				document.body.appendChild(a);
				a.click();

				document.body.removeChild(a);
			} catch (error) {
				alert("Error downloading PDF");
				console.error("Error downloading PDF:", error);
			}
		}
	};

	return (
		<button onClick={handleDownloadReport}>
			<i className="fa-solid fa-file-arrow-down"></i>
			Report
		</button>
	);
};

export default DownloadReport;
