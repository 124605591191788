import React, { useState, useContext, useEffect, useMemo } from "react";
import "../Header/HeaderNav.css";
import StateContext from "../../context/stateGlobal/StateContext";
import axios from "axios";
import Cookies from "universal-cookie";
import CryptoJS from "crypto-js";
import { Link, useParams, useNavigate } from "react-router-dom";
import Login from "./Login";
import DownloadReport from "../DownloadReport/DownloadReport";
import HomeIcon from "@mui/icons-material/Home";
import * as THREE from "three";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import PDFInfoModal from "../PDFInfoModal/PDFInfoModal";

const cookies = new Cookies();

export default function HeaderNavRight({ setToggleBtnMenu, appDivRef }) {
	let publicUrl = process.env.PUBLIC_URL;
	const baseUrl = process.env.REACT_APP_BASE_URL;

	const globalState = useContext(StateContext);
	const [showMessage, setShowMessage] = useState(false);
	// const [userName, setUserName] = useState("");
	const [shareUrl, setShareUrl] = useState("");
	const [showLogin, setShowLogin] = useState(false);
	const [patientName, setPatientName] = useState("");
	const [patientLowerData, setPatientLowerData] = useState(null);
	const [patientUpperData, setPatientUpperData] = useState(null);
	const [patientIPRData, setPatientIPRData] = useState(false);
	const [patientIPRJsonData, setPatientIPRJsonData] = useState([]);
	const [doctorName, setDoctorName] = useState(null);
	const [helpFormData, setHelpFormData] = useState({
		message: "",
		mobileNumber: "",
	});
	const [showPdfModal, setShowPdfModal] = useState(false);
	const [isGenerating, setIsGenerating] = useState(false);
	const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);

	const navigate = useNavigate();

	const { patientId, caseId, uuid } = useParams();
	const upperJawsIprData =
		Array.isArray(patientIPRJsonData) &&
		patientIPRJsonData.filter((item) => item.jaw === "upper").length > 0;
	const lowerJawsIprData =
		Array.isArray(patientIPRJsonData) &&
		patientIPRJsonData.filter((item) => item.jaw === "lower").length > 0;
	const handleClick = () => {
		setShowMessage(!showMessage);
	};
	const strapiUrl = "https://pacs-auth.dent-scan.com";
	useEffect(() => {
		if (globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(true);
			globalState.playPauseRef.current.style.display = "none";
		} else if (!globalState.isLoggedIn && globalState.addingIprDataBool) {
			globalState.setIsLoggedInForIpr(false);
			globalState.playPauseRef.current.style.display = "none";
			if (globalState.viewer) {
				if (globalState.viewer.scene) {
					while (globalState.viewer.scene.getObjectByName("iprGroup")) {
						globalState.actions.removeObjWithChildren(
							globalState.viewer.scene.getObjectByName("iprGroup"),
						);
					}
				}
				globalState.actions.disposeThreeViewer(globalState.viewer);
			}
			globalState.setIsFileDropped(false);
			globalState.footerRef.current.style.display = "none";
		}
	}, [globalState.isLoggedIn, globalState.addingIprDataBool]);

	useEffect(() => {
		let jwtToken = cookies.get("jwtToken", { path: "/" });
		let userName = cookies.get("userName", { path: "/" });
		if (jwtToken) {
			globalState.setIsLoggedIn(true);
			if (userName) {
				// setUserName(userName);
				if (globalState.tempTreatmentData) {
					// let currentTreatmentPlan =
					// 	globalState.selectedTreatmentPlan - 1;
					let currentTreatmentPlan = globalState.tempTreatmentData.find(
						(plan) =>
							parseInt(plan.attributes.treatment_plan_version) ===
							globalState.selectedTreatmentPlan,
					);

					let patientData = currentTreatmentPlan.attributes;
					setPatientName(patientData.patient_name);
					setPatientLowerData(patientData.lower_steps_number);
					setPatientUpperData(patientData.upper_steps_number);
					setPatientIPRData(patientData.ipr_data);
					setPatientIPRJsonData(patientData.ipr_data);
					setDoctorName(patientData.doctor_name);
				}
			}
			userRole({ token: jwtToken });
		} else {
			globalState.setIsLoggedIn(false);
		}
	}, [
		globalState.tempTreatmentData,
		shareUrl,
		globalState.isLoggedIn,
		upperJawsIprData,
		lowerJawsIprData,
	]);

	const getAttachment = () => {
		return (
			globalState.viewer?.scene?.getObjectsByProperty(
				"customType",
				"attachment",
			).length > 0
		);
	};
	const isAttachment = useMemo(() => getAttachment(), [globalState.viewer]);

	const userRole = async ({ token }) => {
		axios
			.get(`${strapiUrl}/api/users/me?populate=role`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				if (!res.data.blocked) {
					globalState.setTreatmentData(globalState.tempTreatmentData);
					// setIsLoginOpen(false);
					// appDivRef.current.classList.remove("overlay");
				}
				return res.data;
			})
			.catch((error) => {
				if (error.response.status === 401) {
					cookies.remove("jwtToken", { path: "/" });
					cookies.remove("userName", { path: "/" });
					cookies.remove("userRole", { path: "/" });
					globalState.setIsLoggedIn(false);
				}
			});
	};

	const treatmentPlanChange = (e) => {
		// treatmentUiId is the id of the selected treatment selected by the user
		let treatmentUiId = e.target.value;
		const selectedTreatmentData = globalState.treatmentData.find(
			(data) => data.attributes.treatment_plan_version === treatmentUiId,
		);
		let url = selectedTreatmentData.attributes.link;
		if (process.env.NODE_ENV === "development") {
			url = `http://localhost:3000/${patientId}/${treatmentUiId}/${
				url.split("/").slice(-1)[0]
			}`;
		}
		// Redirect to new url
		window.location.replace(url);
	};

	const handleShareLink = () => {
		const payload = {
			patient_id: patientId,
			treatment_plan_version: caseId,
			uuid: uuid,
			exp:
				Math.floor(Date.now() / 1000) +
				process.env.REACT_APP_SHARE_LINK_EXPIRY_DAY * 24 * 3600, // Set expiration time to 1 hour from now 3600
		};
		const token = encodeURIComponent(
			CryptoJS.AES.encrypt(
				JSON.stringify(payload),
				process.env.REACT_APP_TOKEN_SECRET_KEY,
			).toString(),
		);
		setShareUrl(`${window.location.origin}/share/${token}`);
	};

	const handleCopyClick = () => {
		let textArea = document.getElementById("shareUrl");
		textArea.select();
		document.execCommand("copy");
		textArea.blur();
		alert("Link copied to clipboard");
	};

	const handleLoginButton = () => {
		// globalState.setIsLoggedIn(true);
		setShowLogin(true);
		appDivRef.current.classList.add("overlay");

		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
	};

	const isLogout = async () => {
		cookies.remove("jwtToken", { path: "/" });
		cookies.remove("userName", { path: "/" });
		cookies.remove("userRole", { path: "/" });
		globalState.setIsLoggedIn(false);
		setShowLogin(false);
		setTimeout(() => {
			if (uuid) {
				globalState.viewer.onWindowResize(globalState.viewer);
			}
		}, 100);
		// appDivRef.current.classList.remove("overlay");
	};

	const handleHelpSubmit = () => {
		// console.log(baseUrl, patientId, caseId, uuid);
		let data = {
			doctorName: doctorName,
			patientName: patientName,
			treatmentPlanVersion: caseId,
			viewerUrl: `${baseUrl}/${patientId}/${caseId}/${uuid}`,
			message: helpFormData.message,
			mobileNumber: helpFormData.mobileNumber,
		};
		// console.log(data)
		if (!globalState.isLoggedIn) return;
		axios
			.post("https://notify.dent-scan.com/send-email", data, {
				headers: {
					"Content-Type": "application/json",
					"api-key": "45dcd151-964f-4683-be0e-504de51c3011",
				},
			})
			.then((response) => {
				document.getElementById("closeModalButton").click();
				alert("Request sent. We will get back to you shortly");
			})
			.catch((error) => {
				if (error.response.data.errors.length > 0) {
					alert(error.response.data.errors[0].msg);
				} else {
					alert("error sending email. Please try again later");
				}
			})
			.finally(() => {
				setHelpFormData({
					message: "",
					mobileNumber: "",
				});
			});
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setHelpFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const captureThreeViews = async (viewer) => {
		// Store original camera state
		const originalPosition = viewer.camera.position.clone();
		const originalRotation = viewer.camera.rotation.clone();
		const originalZoom = viewer.camera.zoom;
		const originalControlsEnabled = viewer.controls.enabled;
		// Temporarily disable controls and set background to white
		viewer.controls.enabled = false;
		viewer.scene.background = new THREE.Color("white"); // Set background color to white

		const images = [];
		const views = [
			{
				name: "Right View",
				position: new THREE.Vector3(50, 0, 0),
				rotation: new THREE.Euler(0, -Math.PI / 2, 0, "XYZ"), // Added 'XYZ' order
			},
			{
				name: "Front View",
				position: new THREE.Vector3(0, 0, 50),
				rotation: new THREE.Euler(0, 0, 0, "XYZ"), // Added 'XYZ' order
			},
			{
				name: "Left View",
				position: new THREE.Vector3(-50, 0, 0),
				rotation: new THREE.Euler(0, Math.PI / 2, 0, "XYZ"), // Added 'XYZ' order
			},
		];
		try {
			for (const view of views) {
				// Update camera
				viewer.camera.position.copy(view.position);
				viewer.camera.rotation.copy(view.rotation);
				viewer.camera.lookAt(0, 0, 0);
				viewer.camera.updateProjectionMatrix();

				// Need to wait for the scene to update
				await new Promise((resolve) => setTimeout(resolve, 500));

				// Render and capture
				viewer.renderer.render(viewer.scene, viewer.camera);
				images.push({
					name: view.name,
					dataUrl: viewer.renderer.domElement.toDataURL("image/png"),
				});
			}
		} finally {
			// Restore original camera state
			viewer.camera.position.copy(originalPosition);
			viewer.camera.rotation.copy(originalRotation);
			viewer.camera.zoom = originalZoom;
			viewer.camera.updateProjectionMatrix();
			viewer.controls.enabled = originalControlsEnabled;
			viewer.scene.background = new THREE.Color("rgb(112, 114, 115)"); // Restore original background color
			viewer.renderer.render(viewer.scene, viewer.camera);
		}
		return images;
	};
	// Modify the generatePDF function to use the panoramic images
	const generatePDF = async (viewer, jsonData, pdfInfo) => {
		try {
			const images = await captureThreeViews(viewer);
			const doc = new jsPDF();
			const pageWidth = doc.internal.pageSize.width;
			const pageHeight = doc.internal.pageSize.height;

			// Function to add page border and number
			const addPageBorderAndNumber = (pageNum, totalPages) => {
				// Add border
				doc.setDrawColor(0);
				doc.setLineWidth(0.5);
				doc.rect(10, 10, pageWidth - 20, pageHeight - 20);

				// Add page number
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.text(
					`Page ${pageNum}/${totalPages}`,
					pageWidth / 2,
					pageHeight - 15,
					{ align: "center" },
				);
			};

			// Calculate total pages (1 for views + 1 for IPR if exists)
			const totalPages =
				globalState.iprJsonObj && Array.isArray(globalState.iprJsonObj) ? 2 : 1;

			// First page with border and page number
			addPageBorderAndNumber(1, totalPages);

			// Header section with optimized spacing
			doc.setFontSize(10);
			doc.setTextColor(100);
			const currentDate = new Date().toLocaleDateString();
			doc.setFont(undefined, "bold");
			doc.text(`Date: `, 15, 20);
			doc.text(`Patient ID: `, 15, 25);
			doc.text(`Patient Name: `, 15, 30);
			doc.text(`Doctor Name: `, 15, 35);
			doc.setFont(undefined, "normal");
			doc.text(`${currentDate}`, 40, 20);
			doc.text(`${pdfInfo.patientUploadId}`, 40, 25);
			doc.text(`${pdfInfo.patientUploadName}`, 40, 30);
			doc.text(`${pdfInfo.doctorUploadName}`, 40, 35);

			// Logo positioned higher and more to the right
			doc.addImage(
				`${process.env.PUBLIC_URL}/Logo.png`,
				"PNG",
				pageWidth - 60,
				15,
				45,
				15,
			);

			// Main title with larger font and better spacing
			doc.setFontSize(14);
			doc.setTextColor(0);
			doc.setFont(undefined, "bold");
			doc.text("Attachment Views", pageWidth / 2, 50, { align: "center" });
			doc.setFont(undefined, "normal");

			// Optimized image layout with better spacing
			const margin = 15;
			const availableWidth = pageWidth - 2 * margin;
			const imageWidth = availableWidth * 0.85; // 85% of available width
			const imageHeight = imageWidth * 0.45; // Maintain aspect ratio
			const startY = 60; // Start images lower to accommodate header
			const spaceBetweenImages = 0; // Reduced space between images

			// Place images one below another with optimized spacing
			images.forEach((image, index) => {
				const yPosition = startY + index * (imageHeight + spaceBetweenImages);
				const xPosition = (pageWidth - imageWidth) / 2;

				// View label with smaller font

				// doc.setFontSize(11);
				// doc.setFont(undefined, "bold");
				// doc.text(image.name, pageWidth / 2, yPosition - 2, { align: "center" });
				// doc.setFont(undefined, "normal");

				// Add image with slight border

				// doc.setDrawColor(200);
				// doc.setLineWidth(0.1);
				// doc.rect(xPosition, yPosition, imageWidth, imageHeight);

				doc.addImage(
					image.dataUrl,
					"PNG",
					xPosition,
					yPosition,
					imageWidth,
					imageHeight,
					undefined,
					"FAST",
				);
			});

			// IPR Data section with consistent styling
			if (globalState.iprJsonObj && Array.isArray(globalState.iprJsonObj)) {
				doc.addPage();
				addPageBorderAndNumber(2, totalPages);

				doc.setFontSize(14);
				doc.setFont(undefined, "bold");
				doc.text("IPR Data", pageWidth / 2, 25, { align: "center" });
				doc.setFont(undefined, "normal");

				// Upper Jaw IPR Data
				const upperJawData = globalState.iprJsonObj.filter(
					(item) => item.jaw === "upper",
				);
				doc.setFontSize(12);
				doc.setFont(undefined, "bold");
				doc.text("Upper Jaw", 15, 35);
				doc.setFont(undefined, "normal");
				if (upperJawData.length > 0) {
					doc.autoTable({
						startY: 40,
						margin: { left: 15, right: 15 },
						head: [["Sr. No", "Jaw", "Note", "Step No.", "Value", "Between"]],
						body: upperJawData.map((item, index) => [
							index + 1,
							item.jaw,
							item.note,
							item.step_no,
							item.value,
							item.between,
						]),
						styles: { fontSize: 10 },
						headStyles: { fillColor: [100, 100, 100] },
					});
				} else {
					doc.setFontSize(10);
					doc.text("No Upper Jaw IPR Data Available", 15, 45);
				}

				// Lower Jaw IPR Data
				const lowerJawData = globalState.iprJsonObj.filter(
					(item) => item.jaw === "lower",
				);
				const startY =
					upperJawData.length > 0 ? doc.previousAutoTable.finalY + 15 : 60;

				doc.setFontSize(12);
				doc.setFont(undefined, "bold");
				doc.text("Lower Jaw", 15, startY - 5);
				doc.setFont(undefined, "normal");

				if (lowerJawData.length > 0) {
					doc.autoTable({
						startY: startY,
						margin: { left: 15, right: 15 },
						head: [["Sr. No", "Jaw", "Note", "Step No.", "Value", "Between"]],
						body: lowerJawData.map((item, index) => [
							index + 1,
							item.jaw,
							item.note,
							item.step_no,
							item.value,
							item.between,
						]),
						styles: { fontSize: 10 },
						headStyles: { fillColor: [100, 100, 100] },
					});
				} else {
					doc.setFontSize(10);
					doc.text("No Lower Jaw IPR Data Available", 15, startY + 5);
				}
			}

			doc.save(`${pdfInfo.patientUploadName} IPR-Attachment Report.pdf`);
		} catch (error) {
			console.error("Error generating PDF:", error);
			throw error;
		}
	};
	// Update the handlePrintPDF function to handle errors
	const handlePrintPDF = () => {
		if (globalState.viewer) {
			setShowPdfModal(true);
		}
	};

	const handlePdfSubmit = async (formData) => {
		setIsGenerating(true);
		try {
			await globalState.actions.generateJsonData(globalState);
			await generatePDF(globalState.viewer, globalState.iprJsonObj, formData);
			setShowPdfModal(false);
		} catch (error) {
			console.error("Error generating PDF:", error);
			alert("Error generating PDF. Please try again.");
		} finally {
			setIsGenerating(false);
		}
	};

	return (
		<>
			<div className="HeaderNav-Right">
				<div className="HeaderNav-Right-List">
					<div className="HeaderNav-Right-Inner">
						{/* console.log({globalState.iprData}); */}
						{globalState.isLoggedIn && uuid && (
							<div className="HeaderNav-user">
								<div className="HeaderNav-userBox">
									<div className="HeaderNav-userBox__col HeaderNav-userBox__col--name">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Patient:{" "}
											</span>
											<span
												className="HeaderNav-userBox__value"
												title={patientName ? patientName : "Unknown"}
											>
												{patientName ? patientName : "Unknown"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Doctor: </span>
											<span className="HeaderNav-userBox__value">
												{doctorName ? doctorName : "Unknown"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">IPR: </span>
											<span className="HeaderNav-userBox__value">
												{patientIPRData ? "Yes" : "No"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">
												Attachments:{" "}
											</span>
											<span className="HeaderNav-userBox__value">
												{isAttachment ? "Yes" : "No"}
											</span>
										</div>
									</div>
									<div className="HeaderNav-userBox__col">
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Upper: </span>
											<span className="HeaderNav-userBox__value">
												{patientUpperData ? patientUpperData : "N/A"}
											</span>
										</div>
										<div className="HeaderNav-userBox__row">
											<span className="HeaderNav-userBox__label">Lower: </span>
											<span className="HeaderNav-userBox__value">
												{patientLowerData ? patientLowerData : "N/A"}
											</span>
										</div>
									</div>
								</div>

								<div className="HeaderNav-download">
									<DownloadReport />
								</div>
							</div>
						)}
						<div className="HeaderNav-dropdown">
							<button
								className="toggle-btn"
								onClick={() => {
									setToggleBtnMenu(true);
									appDivRef.current.classList.add("overlay");
								}}
							>
								<img src={`${publicUrl}/assets/img/bar.svg`} alt="bar"></img>
							</button>
							{(globalState.isLoggedIn && uuid && (
								<select
									className="form-select"
									aria-label="Default select example"
									onChange={treatmentPlanChange}
									value={globalState.selectedTreatmentPlan || ""}
								>
									{globalState.treatmentData &&
										globalState.treatmentData.map((item, index) => {
											let treatmentId = item.attributes.treatment_plan_version;
											return (
												<option key={index} value={treatmentId}>
													Treatment Plan {treatmentId}
												</option>
											);
										})}
								</select>
							)) ||
								(uuid && (
									<div className="treatment-ipr-data">
										Treatment Plan {caseId}
									</div>
								))}
						</div>
						{globalState.isLoggedIn && uuid && (
							<>
								<div className="popup homeIcon" title="Home">
									<button
										className="HeaderNav-Msg-Btn"
										onClick={() => navigate("/", { replace: true })}
									>
										<i className="fa-solid fa-house"></i>
										<span className="shareText">Home</span>
									</button>
								</div>
								<div
									className="helpButton"
									// onClick={handleClick}
								>
									{patientIPRData && (
										<button
											className="HeaderNav-Msg-Btn showIprBtn"
											data-bs-toggle="modal"
											data-bs-target="#staticBackdropIPR"
										>
											{/* <i className="fa-solid fa-message btnIcon"></i> */}
											<i className="fa-solid fa-file btnIcon"></i>
											Show IPR
										</button>
									)}
									<button
										className="HeaderNav-Msg-Btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdropHelp"
										id="closeModalButton"
									>
										<i className="fa-solid fa-message btnIcon"></i>
										Contact us
									</button>
									{showMessage && (
										<span className="popuptext" id="myPopup">
											Lorem Ipsum is simply dummy text of the printing and
											typesetting industry. Lorem Ipsum has been the industry's
											standard dummy text ever since the 1500s, when an unknown
											printer took a galley of type and scrambled it to make a
											type specimen book. It has survived not only five
											centuries, but also the leap into electronic typesetting,
											remaining essentially unchanged. It was popularised in the
											1960s with the release of Letraset sheets containing Lorem
											Ipsum passages, and more recently with desktop
											<p className="date-meg">Dent scan 18/09/2023 16:20</p>
										</span>
									)}
								</div>
								<div
									className="modal fade"
									id="staticBackdropHelp"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>

											<div className="modal-body">
												<input
													type="text"
													id="helpPhone"
													name="mobileNumber"
													className="form-control mb-2"
													placeholder="Mobile Number"
													value={helpFormData.mobileNumber}
													onChange={handleInputChange}
												/>
												<textarea
													id="helpMessage"
													name="message"
													className="form-control"
													placeholder="Message"
													value={helpFormData.message}
													onChange={handleInputChange}
												></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleHelpSubmit}
												>
													Send
												</button>
											</div>
										</div>
									</div>
								</div>

								<div
									className="modal fade"
									id="staticBackdropIPR"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropHelpLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>

											<div className="modal-body">
												<div>
													{upperJawsIprData ? (
														<>
															<h6>Upper Jaw</h6>
															<table className="table">
																<thead>
																	<tr>
																		<th>Sr. No</th>
																		<th>Jaw</th>
																		<th>Note</th>
																		<th>Step No.</th>
																		<th>Value</th>
																		<th>Between</th>
																	</tr>
																</thead>
																<tbody>
																	{patientIPRJsonData &&
																		patientIPRJsonData
																			.filter((item) => item.jaw === "upper")
																			.map((item, index) => (
																				<tr key={index}>
																					<td>{index + 1}</td>
																					<td>{item.jaw}</td>
																					<td>{item.note}</td>
																					<td>{item.step_no}</td>
																					<td>{item.value}</td>
																					<td>{item.between}</td>
																				</tr>
																			))}
																</tbody>
															</table>
														</>
													) : (
														<h6>Upper Jaw - No Data Available</h6>
													)}
													<br></br>
													{lowerJawsIprData ? (
														<>
															<h6>Lower Jaw </h6>
															<table className="table">
																<thead>
																	<tr>
																		<th>Sr. No</th>
																		<th>Jaw</th>
																		<th>Note</th>
																		<th>Step No.</th>
																		<th>Value</th>
																		<th>Between</th>
																	</tr>
																</thead>
																<tbody>
																	{patientIPRJsonData &&
																		patientIPRJsonData
																			.filter((item) => item.jaw === "lower")
																			.map((item, index) => (
																				<tr key={index}>
																					<td>{index + 1}</td>
																					<td>{item.jaw}</td>
																					<td>{item.note}</td>
																					<td>{item.step_no}</td>
																					<td>{item.value}</td>
																					<td>{item.between}</td>
																				</tr>
																			))}
																</tbody>
															</table>
														</>
													) : (
														<h6>Lower Jaw - No Data Available</h6>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
						{globalState.isLoggedIn && uuid && (
							<>
								<div className="HeaderNav-share">
									<button
										onClick={handleShareLink}
										className="share-btn"
										data-bs-toggle="modal"
										data-bs-target="#staticBackdrop"
										title="Share"
									>
										<i className="fa-solid fa-share-nodes btnIcon"></i>
										<span className="shareText">Share</span>
									</button>
								</div>
								<div
									className="modal fade"
									id="staticBackdrop"
									data-bs-backdrop="static"
									data-bs-keyboard="false"
									tabIndex="-1"
									aria-labelledby="staticBackdropLabel"
									aria-hidden="true"
								>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header">
												<button
													type="button"
													className="btn-close"
													data-bs-dismiss="modal"
													aria-label="Close"
												></button>
											</div>
											<div className="modal-body">
												<textarea
													id="shareUrl"
													className="copyUrlTextarea"
													readOnly
													value={shareUrl}
												></textarea>
											</div>
											<div className="modal-footer">
												<button
													type="button"
													className="button"
													onClick={handleCopyClick}
												>
													Copy
												</button>
												<a
													href={shareUrl}
													target="_blank"
													rel="noopener noreferrer"
												>
													<button type="button" className="button">
														Open
													</button>
												</a>
											</div>
										</div>
									</div>
								</div>
							</>
						)}

						{globalState.isFileDropped && (
							<div className="HeaderNav-addipr">
								<button
									onClick={handlePrintPDF}
									className={`print-button ${isGenerating ? "generating" : ""}`}
									disabled={isGenerating}
								>
									{isGenerating ? (
										<>
											<i className="fa-solid fa-spinner fa-spin"></i>{" "}
											Generating...
										</>
									) : (
										<>
											<i className="fa-solid fa-print"></i> Print Panoramic PDF
										</>
									)}
								</button>
								<button
									ref={globalState.addIprButtonHeaderRef}
									onClick={() =>
										globalState.actions.activateAddIpr(globalState)
									}
									className="HeaderNav-addipr__btn"
								>
									Add IPR
								</button>
								<button
									onClick={() =>
										globalState.actions.generateJsonData(globalState)
									}
									className="HeaderNav-addipr__btn"
								>
									Generate Json Data
								</button>
							</div>
						)}

						<div className="HeaderNav-auth">
							{globalState.isLoggedIn ? (
								<>
									<div
										className={`HeaderNav-auth__welcome ${uuid ? "uuid" : ""}`}
									>
										Welcome,
										<span className="HeaderNav-auth__username">
											{globalState.nameOfUser ?? "N/A"}
										</span>
									</div>

									<button onClick={isLogout} className="HeaderNav-auth__logout">
										<i className="fa-solid fa-arrow-right-from-bracket btnIcon"></i>
										Logout
									</button>
								</>
							) : (
								<>
									<button
										className="HeaderNav-auth__login"
										onClick={handleLoginButton}
									>
										<i className="fa-solid fa-arrow-right-to-bracket btnIcon"></i>
										Login
									</button>

									{showLogin && (
										<Login appDivRef={appDivRef} loginType="header" />
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
			{showPdfModal && (
				<PDFInfoModal
					onSubmit={handlePdfSubmit}
					onClose={() => setShowPdfModal(false)}
				/>
			)}
		</>
	);
}
