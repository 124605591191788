import * as THREE from 'three'

class viewManager {
    constructor(threeViewer) {
        this.viewer = threeViewer;
    }
    moveCamera(pos, rot, xAxis) {
        let currentSphere = new THREE.Spherical().setFromCartesianCoords(this.viewer.camera.position.x, this.viewer.camera.position.y, this.viewer.camera.position.z)
        let desiredSphere = new THREE.Spherical().setFromCartesianCoords(pos.x, pos.y, pos.z)
        let currentTheta = currentSphere.theta
        let currentPhi = currentSphere.phi

        const desiredTheta = desiredSphere.theta;
        const desiredPhi = desiredSphere.phi;
        const duration = 550; // Animation duration in milliseconds
        let startTime = null;



        // Use an arrow function to capture the correct 'this' context
        const animate = (time) => {
            if (!startTime) {
                startTime = time;
            }
            const progress = (time - startTime) / duration;

            if (progress >= 1) {
                // Animation complete
                this.viewer.camera.position.copy(pos);
                this.viewer.camera.rotation.copy(rot);
                this.viewer.controls.enabled = true;
            } else {
                // Increase the exponent for a stronger ease-out effect
                const exponent = 3; // Adjust this value as needed
                const easedProgress = 1 - Math.pow(1 - progress, exponent);

                const newTheta = currentTheta + (desiredTheta - currentTheta) * easedProgress;
                const newPhi = currentPhi + (desiredPhi - currentPhi) * easedProgress;

                const v = new THREE.Vector3().setFromSphericalCoords(50, newPhi, newTheta);
                this.viewer.camera.position.copy(v);

                requestAnimationFrame(animate);
            }
        }

        // Start the animation
        requestAnimationFrame(animate);


        this.viewer.controls.enabled = false;
        requestAnimationFrame(animate);
    }

    async setView(newPosition, newRotation, xaxis) {
        this.viewer.controls.target.set(0,0,0)
        this.zoomInToDefault()
        let countPos = 0
        let startPos = this.viewer.camera.position.clone().round()
        let endPos = newPosition.clone().round()
        if (startPos.x.toFixed(2) == endPos.x.toFixed(2)) {
            countPos++
        } if (startPos.y.toFixed(2) == endPos.y.toFixed(2)) {
            countPos++
        } if (startPos.z.toFixed(2) == endPos.z.toFixed(2)) {
            countPos++
        }

        //if start and end position is same, no animation 
        if (countPos == 3) {
            return
        }
        if (this.viewer.camera.position.x.toFixed(0) == newPosition.x.toFixed(0)) {
            this.moveCamera(newPosition, newRotation, xaxis)
        } else {
            if (!xaxis) {
                if (this.viewer.camera.position.y.toFixed(2) != 0 && endPos.y == 0) {

                    this.moveCamera(newPosition, newRotation, xaxis)

                } else {
                    if (countPos > 0) {
                        this.moveCamera(newPosition, newRotation, xaxis)
                    } else {
                        this.moveCamera(newPosition, newRotation, xaxis)
                    }
                }
            } else {
                this.moveCamera(newPosition, newRotation, xaxis)
            }
        }
    }
    zoomInToDefault() {
        if (this.viewer.camera.zoom.toFixed(0) === this.adjustZoom().toFixed(0)) {
            // No zoom needed, return a resolved Promise or perform other actions.
            // return Promise.resolve();
        } else {
            let factor = (this.adjustZoom() - this.viewer.camera.zoom) / 30;
            let zoomCount = 0;

            // Use an arrow function or bind this to the setInterval callback.
            let id = setInterval(() => {
                if (zoomCount === 30) {
                    clearInterval(id);
                } else {
                    this.viewer.camera.zoom += factor;
                    this.viewer.camera.updateProjectionMatrix();
                    this.viewer.controls.update();
                    zoomCount++;
                }
            }, 1);
        }
    }
    adjustZoom() {
        //here 2.33 is our ratio of canvas size in standard 1920 x 1080 screen
        let widthToHeightRatio = this.viewer.canvasWidth / this.viewer.canvasHeight
        if (this.viewer.canvasWidth >= this.viewer.canvasHeight) {
            return this.viewer.canvasWidth * (10 / 1920) * (2.33 / widthToHeightRatio)
        } else {
            if (this.viewer.canvasWidth / this.viewer.canvasHeight > 0.8) {
                return this.viewer.canvasWidth * (10 / 1920) * (2.33)
                // return scope.canvasWidth * (10 / 1920) * (2.33 / widthToHeightRatio) * widthToHeightRatio
            } else {
                return 2.33 / widthToHeightRatio
            }
        }
    }

}
export default(viewManager)