import React, { useContext, useRef, useState } from "react";
import "../Header/HeaderNav.css";
import axios from "axios";
import Cookies from "universal-cookie";
import StateContext from "../../context/stateGlobal/StateContext";
import { useParams } from "react-router-dom";
import HeaderLogo from "./HeaderLogo";
const cookies = new Cookies();

const strapiUrl = "https://pacs-auth.dent-scan.com";

export default function Login({ appDivRef, loginType }) {
	const globalState = useContext(StateContext);
	const { patientId, caseId, uuid } = useParams();
	const emailRef = useRef();
	const passRef = useRef();
	const [loading, setLoading] = useState(false);

	const userLogin = async () => {
		try {
			const email = emailRef.current.value;
			const password = passRef.current.value;

			if (!email || !password) {
				alert("Username and password are required.");
				return;
			}

			const response = await axios.post(`${strapiUrl}/api/auth/local`, {
				identifier: email,
				password: password,
			});

			if (response.status === 200) {
				globalState.setLoggedUserId(response.data.user.id);
				const expirationDate1 = new Date();
				expirationDate1.setDate(expirationDate1.getDate() + 7);
				cookies.set("loggedInUserId", response.data.user.id, {
					path: "/",
					expires: expirationDate1,
				});
				const loginData = await fetchUserRoleData({
					token: response.data.jwt,
				});

				// console.log(loginData);

				if (!loginData.blocked) {
					const expirationDate = new Date();
					expirationDate.setDate(expirationDate.getDate() + 7);
					cookies.set("jwtToken", response.data.jwt, {
						path: "/",
						expires: expirationDate,
					});
					cookies.set("userName", response.data.user.username, {
						path: "/",
						expires: expirationDate,
					});
					cookies.set("user_name", response.data.user.name, {
						path: "/",
						expires: expirationDate,
					});
					cookies.set("userRole", loginData.role.type, {
						path: "/",
						expires: expirationDate,
					});
					globalState.setIsLoggedIn(true);
					globalState.setNameOfUser(response.data.user.name);
				} else {
					globalState.setIsLoggedIn(false);
					alert("You are Blocked");
				}
			}
		} catch (error) {
			console.error("Error during login:", error);
			alert(error.response.data.error.message);
		} finally {
			setLoading(false);
			if (appDivRef.current.classList.contains("overlay")) {
				// Remove the "overlay" class
				appDivRef.current.classList.remove("overlay");
			}
		}
	};

	const fetchUserRoleData = async ({ token }) => {
		const url = `${strapiUrl}/api/users/me?populate=role`;
		const headers = {
			Authorization: `Bearer ${token}`,
		};

		const response = await axios.get(url, { headers });

		return response.data;
	};

	return (
		<div className={`${loginType !== "header" ? "login__main__block" : ""}`}>
			<div className="login-popup">
				<div className="logo_box">
					<HeaderLogo type="login"/>
				</div>
				
				<div className="login__title__block">
					<span className="login__title">Login</span>
				</div>
			
				<div className="input-container">
					<label htmlFor="email" className="form-label">
						Email:
					</label>
					<input
						type="email"
						id="email"
						placeholder="Enter your email"
						ref={emailRef}
					/>
				</div>
				<div className="input-container">
					<label htmlFor="password" className="form-label">
						Password:
					</label>
					<input
						type="password"
						id="password"
						placeholder="Enter your password"
						ref={passRef}
					/>
				</div>
				<button
					className={`login-popup__submit-button ${
						loading ? "loading" : ""
					} `}
					onClick={() => {
						setLoading(true);
						userLogin();
					}}
					disabled={loading}
				>
					<div className="button-spinner"></div>
					Login
				</button>
			</div>
		</div>
	);
}
