import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import PatientList from "../Patient/PatientList";
import stateContext from "../../context/stateGlobal/StateContext";

const cookies = new Cookies();

function PatientContent({ appDivRef }) {
	const globalState = useContext(stateContext);
	const [globalFilter, setGlobalFilter] = useState(undefined);
	const [columnFilter, setColumnFilter] = useState([]);
	const [columnSort, setColumnSort] = useState([]);
	const [density, setDensity] = useState("compact");
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isRefetching, setIsRefetching] = useState(false);
	const [error, setError] = useState(null);
	const [token, setToken] = useState("");

	const [rowCount, setRowCount] = useState(0);
	const [pagination, setPagination] = useState({
		pageIndex: 0,
		pageSize: 10,
	});

	const userName = cookies.get("userName", { path: "/" });
	const userRole = cookies.get("userRole", { path: "/" });

	useEffect(() => {
		var jwtToken = cookies.get("jwtToken", { path: "/" });
		if (jwtToken) {
			setToken(jwtToken);
		}
	}, []);

	useEffect(() => {
		if (token) {
			fetchData();
		}
	}, [token, pagination.pageIndex, pagination.pageSize, globalFilter, columnFilter, columnSort]);

	const fetchData = async () => {
		setIsLoading(!data.length);
		setIsRefetching(true);

		const loggedInUserId = cookies.get("loggedInUserId", { path: "/" });
		let url = `https://pacs-auth.dent-scan.com/api/ds-viewer-links?pagination[page]=${pagination.pageIndex + 1}&pagination[pageSize]=${pagination.pageSize}`;

		if (userName && userRole === "viewer") {
			url += `&filters[$and][0][doctor_id][$eqi]=${loggedInUserId}`;
		}

		if (globalFilter) {
			url += `&filters[$or][0][patient_id][$containsi]=${globalFilter}`;
			url += `&filters[$or][1][patient_name][$containsi]=${globalFilter}`;
			url += `&filters[$or][2][status][$containsi]=${globalFilter}`;
			if (userRole === "uploader" || userRole === "admin") {
				url += `&filters[$or][3][doctor_name][$containsi]=${globalFilter}`;
			}
		}

		columnFilter.map((item,index) => {
			let key = item.id.split('.').pop();
			url += `&filters[$and][${index}][${key}][$containsi]=${item.value}`
		})
		if(columnSort.length > 0){
			columnSort.map((item,index) => {
				let key = item.id.split('.').pop();
				url += `&sort[1]=${key}:${item.desc? 'DESC': 'ASC'}`
			})
		} else {
			url += `&sort[0]=id:DESC`
		}

		try {
			const response = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (response) {
				setRowCount(response.data.meta.pagination.total);
				setData(response.data.data);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setError(error.response.message);
			if(error.response.status===401){
				cookies.remove("jwtToken", { path: "/" });
				cookies.remove("userName", { path: "/" });
				cookies.remove("userRole", { path: "/" });
				globalState.setIsLoggedIn(false);
			}
		}

		setIsLoading(false);
		setIsRefetching(false);
	};

	return (
		<div>
			<div className="viewer__content">
				<div className="container">
					{error ? (
						<p>{error}</p>
					) : (
						<>
							<PatientList
								data={data}
								isLoading={isLoading}
								isRefetching={isRefetching}
								pagination={pagination}
								setPagination={setPagination}
								rowCount={rowCount}
								globalFilter={globalFilter}
								columnFilter={columnFilter}
								columnSort={columnSort}
								setGlobalFilter={setGlobalFilter}
								setColumnFilter={setColumnFilter}
								setColumnSort={setColumnSort}
								userRole={userRole}
								density={density}
								setDensity={setDensity}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
}

export default PatientContent;
